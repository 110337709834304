import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useHistory } from 'react-router';
import { useSocketContext } from '@/context/socketContext';

import styled from 'styled-components';
import { List, TextField } from '@material-ui/core';
import { UIBox } from '@/components/ui/Box';
import { UICheckbox } from '@/components/ui/Checkbox';
import { Typography } from '@/components/ui/Typography';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import { Autocomplete } from '@material-ui/lab';
import { InventoryItem } from '@/components/layout/InventoryItem';

import { fetchInventoryList } from '@/features/inventory/inventorySlice';
import { InventoyStatusValues } from '@/types/enum';
import { Inventory } from '@/types/inventory';
import { InventoryService } from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { CTAContainer } from '@/components/layout/CTAContainer';

export const StyledInventoryList = styled(List)`
  flex-direction: column;
  margin-top: 28px;
`;

const StyledField = styled(TextField)`
  min-width: 300px;
`;

type Status = keyof Inventory;

type Filter = {
  label: string;
  value: Status;
  onClick: () => void;
};

const PageInventoryVirtualBuddy: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { disconnect } = useSocketContext();
  const [inventoriesError, setInventoriesError] = useState<boolean>(false);
  const [joinInventoryError, setJoinInventoryError] = useState<boolean>(false);
  const [searchedStoreCode, setSearchedStoreCode] = useState<string>('');
  const [inventories, setInventories] = useState<Inventory>({
    ongoing: [],
    scheduled: [],
    passed: [],
    pending: [],
  });
  const [filters, setFilters] = useState<Status[]>([
    'ongoing',
    'passed',
    'pending',
    'scheduled',
  ]);

  const { fetchInventoryListIsLoading, fetchInventoryListHasError } =
    useSelector(state => state.inventory);

  const inventoryList = useSelector(state => state.inventory.inventoryList);
  const virtualBuddyStoreCodes = useSelector(
    state => state.inventory.virtualBuddyStores
  );

  useAsync(async () => {
    await dispatch(
      fetchInventoryList({
        storeCode: searchedStoreCode,
        isFiltered: searchedStoreCode !== '',
      })
    );
  }, [searchedStoreCode]);

  useAsync(async () => {
    await disconnect();
  }, []);

  useEffect(() => {
    if (fetchInventoryListHasError) {
      setInventoriesError(true);
    }
  }, [fetchInventoryListHasError]);

  const scheduledInventoryClickHandler = async (
    inventoryId: string,
    _inventoryStatus?: InventoyStatusValues,
    _isRetry?: boolean,
    inventoryStoreCode?: string
  ): Promise<void> => {
    if (inventoryStoreCode) {
      try {
        await InventoryService.inventoryJoinInventory({
          requestBody: {
            inventoryId,
            storeCode: inventoryStoreCode,
          },
        });
      } catch {
        setJoinInventoryError(true);
      }
    }
  };

  const pendingInventoryClickHandler = async (
    inventoryId: string,
    _inventoryStatus?: InventoyStatusValues,
    _isRetry?: boolean,
    inventoryStoreCode?: string
  ): Promise<void> => {
    if (inventoryStoreCode) {
      try {
        await InventoryService.inventoryJoinInventory({
          requestBody: {
            inventoryId,
            storeCode: inventoryStoreCode,
          },
        });

        sessionStorage.setItem('inventoryStoreCode', inventoryStoreCode);

        history.push(`${AppRoutes.INVENTORY_VIRTUAL_BUDDY}/${inventoryId}`, {
          inventoryStoreCode,
        });
      } catch {
        // TODO - see error reponse and save in local state
        setJoinInventoryError(true);
      }
    }
  };

  const onGoingInventoryClickHandler = async (
    inventoryId: string,
    _inventoryStatus?: InventoyStatusValues,
    _isRetry?: boolean,
    inventoryStoreCode?: string
  ): Promise<void> => {
    if (inventoryStoreCode) {
      try {
        await InventoryService.inventoryJoinInventory({
          requestBody: {
            inventoryId,
            storeCode: inventoryStoreCode,
          },
        });

        sessionStorage.setItem('inventoryStoreCode', inventoryStoreCode);

        history.push(`${AppRoutes.INVENTORY_VIRTUAL_BUDDY}/${inventoryId}`, {
          inventoryStoreCode,
        });
      } catch {
        // TODO - see error reponse and save in local state
        setJoinInventoryError(true);
      }
    }
  };

  const searchStoreCodeHandler = (storeCode: string | null): void => {
    setSearchedStoreCode(storeCode || '');
  };

  /**
   * Fill / Clear inventory lists based on filters
   */
  useEffect(() => {
    setInventories(() => {
      const newInventories: Inventory = {
        ongoing: [],
        scheduled: [],
        passed: [],
        pending: [],
      };

      const inventoriesStatus = Array.from(
        Object.keys(newInventories)
      ) as Array<keyof Inventory>;

      for (const status of inventoriesStatus) {
        newInventories[status] = filters.includes(status)
          ? inventoryList[status]
          : [];
      }

      return newInventories;
    });
  }, [filters, inventoryList]);

  const checkboxHandler = useCallback((status: Status) => {
    setFilters(prevState => {
      if (prevState.includes(status)) {
        return prevState.filter(v => v !== status);
      }

      return [...prevState, status];
    });
  }, []);

  const inventoryFilters: Filter[] = useMemo(() => {
    return [
      {
        label: t('ongoing'),
        value: 'ongoing',
        onClick: (): void => checkboxHandler('ongoing'),
      },
      {
        label: t('scheduled'),
        value: 'scheduled',
        onClick: (): void => checkboxHandler('scheduled'),
      },
      {
        label: t('pending'),
        value: 'pending',
        onClick: (): void => checkboxHandler('pending'),
      },
      {
        label: t('past'),
        value: 'passed',
        onClick: (): void => checkboxHandler('passed'),
      },
    ];
  }, [checkboxHandler, t]);

  if (fetchInventoryListIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <UIBox width="100%" flexDirection="column" mb="100px" padding={3}>
        <UIBox width="100%">
          <UIBox ml="auto" alignItems="center">
            <Typography color="grey" margin="0 8px">
              {t('profiling.filterByProcess')}
            </Typography>
            {inventoryFilters.map(({ label, value, onClick }, index) => (
              <UIBox alignItems="center" key={`${value}-${index}`}>
                <UICheckbox
                  checked={filters.includes(value.toLowerCase() as Status)}
                  onClick={onClick}
                />
                <Typography margin="0 16px 0 -4px">{label}</Typography>
              </UIBox>
            ))}
          </UIBox>
        </UIBox>
        <UIBox width="100%" flexDirection="column">
          {inventories.pending.length > 0 && (
            <StyledInventoryList>
              <Typography size="lg" font="heavy">
                {t('pendingValidation')}
              </Typography>
              {inventories.pending.map(inventory => (
                <InventoryItem
                  {...inventory}
                  key={inventory.inventoryId}
                  inventoryClickHandler={pendingInventoryClickHandler}
                  inventoryStatus={inventory.statusName as InventoyStatusValues}
                />
              ))}
            </StyledInventoryList>
          )}
          {inventories.ongoing.length > 0 && (
            <StyledInventoryList>
              <Typography size="lg" font="heavy">
                {t('onGoingInventories')}
              </Typography>
              {inventories.ongoing.map(inventory => (
                <InventoryItem
                  {...inventory}
                  key={inventory.inventoryId}
                  inventoryClickHandler={onGoingInventoryClickHandler}
                  inventoryStatus={inventory.statusName as InventoyStatusValues}
                />
              ))}
            </StyledInventoryList>
          )}
          {inventories.scheduled.length > 0 && (
            <StyledInventoryList>
              <Typography size="lg" font="heavy">
                {t('scheduledInventories')}
              </Typography>
              {inventories.scheduled.map(inventory => (
                <InventoryItem
                  {...inventory}
                  key={inventory.inventoryId}
                  disabled
                  inventoryClickHandler={scheduledInventoryClickHandler}
                  inventoryStatus={inventory.statusName as InventoyStatusValues}
                />
              ))}
            </StyledInventoryList>
          )}
          <StyledInventoryList>
            <UIBox alignItems="center" mb={2} justifyContent="space-between">
              <Typography size="lg" font="heavy">
                {t('pastInventories')}
              </Typography>
              <Autocomplete
                autoComplete
                key="store-codes"
                value={searchedStoreCode}
                onChange={(_e, code): void => {
                  searchStoreCodeHandler(code);
                }}
                options={virtualBuddyStoreCodes}
                getOptionLabel={(option): string => option}
                getOptionSelected={(option, value): boolean => option === value}
                renderInput={(params): JSX.Element => (
                  <StyledField
                    {...params}
                    label={t('profiling.filterByStoreCode')}
                    variant="outlined"
                  />
                )}
              />
            </UIBox>
            {inventories.passed.length > 0 && (
              <>
                {inventories.passed.map(inventory => (
                  <InventoryItem
                    {...inventory}
                    key={inventory.inventoryId}
                    inventoryStatus={
                      inventory.statusName as InventoyStatusValues
                    }
                  />
                ))}
              </>
            )}
          </StyledInventoryList>
        </UIBox>
        <CTAContainer
          type="BACK"
          onClick={(): void => history.push(`${AppRoutes.INTRO}`)}
        />
        <ErrorSnackbar
          open={inventoriesError}
          setIsOpen={setInventoriesError}
          errorMessage={t('error.inventory_loading')}
        />
        <ErrorSnackbar
          open={joinInventoryError}
          setIsOpen={setJoinInventoryError}
          errorMessage={t('error.inventory_join')}
        />
      </UIBox>
    </>
  );
};

export default PageInventoryVirtualBuddy;
