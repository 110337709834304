import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UIAction } from '@/components/ui/Action';
import { Typography } from '@/components/ui/Typography';
import { UIButton } from '@/components/ui/Button';

import { NotificationBar } from '@/components/layout/NotificationBar';

import { CycleDetailsItems } from '@/api';
import { ProductDetailsItems } from '@/api/receive';

// import { cancelLastProductRemoved } from '@/features/sales/salesProductsSlice';
// import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import {
  StyledProductItemWrapper,
  ProductItemWrapper,
} from '@/components/layout/ProductItemWrapper';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductDescription } from '@/components/layout/ProductDescription';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { UIBox } from '@/components/ui/Box';
import { SvgIcon } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { useToggle } from 'react-use';
import { removeNotFoundReason } from '@/features/cycleCount/cycleCountSlice';
import { useAppDispatch } from '@/app/store';

//#region types
export interface StyledScannedProductProps {
  $found?: boolean;
  $notFound?: boolean;
  $unexpected?: boolean;
  $sale?: boolean;
  isScanCompleted?: boolean;
  inPause?: boolean;
}

export interface StyledScannedProductItemProps
  extends StyledScannedProductProps {
  productItem: CycleDetailsItems;
  index?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
  setProductToBeReasoned?: React.Dispatch<
    React.SetStateAction<CycleDetailsItems | undefined>
  >;
  notFoundEpcsWithReason?: string[];
  setNotFoundEpcsWithReason?: React.Dispatch<React.SetStateAction<string[]>>;
  setNotFoundModalReasonVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setUnexpectedModalReasonVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setEpcWithReason?: React.Dispatch<React.SetStateAction<string>>;
  handleAddUnexpected?: (
    product: NonNullable<ProductDetailsItems>,
    epcCode: string
  ) => void;
  handlePrintNotFound?: (
    product: NonNullable<ProductDetailsItems>,
    epcCode: string
  ) => void;
  handleRemoveSaleEpc?: (upcCode: string, epcCode: string) => void;
  handleRemoveSaleProduct?: (upcCode: string, epcCode: string) => void;
  disableActions?: boolean;
  hideActions?: boolean;
  $v2?: boolean;
}
//#endregion

const StyledActions = styled(UIBox)`
  align-items: center;
  align-self: flex-end;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
			align-items: flex-end;
                    flex-direction: column;

										&&& > * {
											margin-right: 0;

											&:first-child {
												order: 2
											}


										}
                }`}

  & > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const ScannedProductItemCycleCount: FC<
  StyledScannedProductItemProps
> = ({
  $found,
  $notFound,
  $unexpected,
  $sale,
  productItem,
  handleAddUnexpected,
  handlePrintNotFound,
  onClick,
  disableActions,
  $v2 = false,
  notFoundEpcsWithReason,
  setNotFoundEpcsWithReason,
  setProductToBeReasoned,
  setNotFoundModalReasonVisibility,
  setUnexpectedModalReasonVisibility,
  setEpcWithReason,
}): JSX.Element => {
  const {
    brandCode,
    brandDescription,
    commodity,
    colorCode,
    epcCodes,
    modelCode,
    price,
    currency,
    styleName,
    upcCode,
    imageUrl,
  } = productItem;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { store } = useSelector(state => state.currentStore);
  const notFoundWithReasonInStore = useSelector(state =>
    state.cycleCount.notFoundWithReason
      .map(({ epcNotFound }) => epcNotFound)
      .flat()
  );

  const [isNotificationBarVisible, setNotificationBarVisible] =
    useState<boolean>(false);

  const [$isAccordionExpanded, toggleAccordion] = useToggle(false);

  const onNotificationCancelClick = (): void => {
    setNotificationBarVisible(false);
    // dispatch(cancelLastProductRemoved());
  };

  const onNotificationBarClose = (): void => {
    setNotificationBarVisible(false);
  };

  const onPrintActionClick = (epcCode: string): void => {
    if (!disableActions) {
      handlePrintNotFound?.(productItem as ProductDetailsItems, epcCode);
    }
  };

  const onReasonActionClick = (epcCode: string): void => {
    if (!disableActions) {
      setNotFoundModalReasonVisibility?.(true);
      setProductToBeReasoned?.(productItem);
      setEpcWithReason?.(epcCode);
    }
  };

  const onReasonDeleteActionClick = (epcCode: string): void => {
    const removedReason = notFoundEpcsWithReason?.filter(
      epc => epc !== epcCode
    );
    setNotFoundEpcsWithReason?.(removedReason || []);
    dispatch(removeNotFoundReason({ upc: upcCode, epc: epcCode }));
  };

  const onUnexpectedActionClick = (epcCode: string): void => {
    if (!disableActions) {
      if (epcCodes?.hasOwnProperty('epcCodes')) {
        handleAddUnexpected?.(productItem as ProductDetailsItems, epcCode);
      } else {
        setUnexpectedModalReasonVisibility?.(true);
        setProductToBeReasoned?.(productItem);
        setEpcWithReason?.(epcCode);
      }
    }
  };

  const isReasoned = (epcCode: string): boolean =>
    notFoundWithReasonInStore
      .map(({ epcNotFoundCode }) => epcNotFoundCode)
      .includes(epcCode.toUpperCase());

  const reasonEpcNotFound = (epcCode: string): (string | undefined)[] =>
    notFoundWithReasonInStore
      .filter(({ epcNotFoundCode }) =>
        epcNotFoundCode.includes(epcCode.toUpperCase())
      )
      .map(({ reasonEpcNotFound }) => reasonEpcNotFound);

  const renderActions = (epcCode: string): JSX.Element => {
    return (
      <>
        {$unexpected && (
          <UIAction
            label={t('add')}
            icon="add"
            disabled={disableActions}
            onClick={(): void => onUnexpectedActionClick(epcCode)}
          />
        )}
        <UIBox flexDirection="column">
          {$notFound && (
            <StyledActions>
              {isReasoned(epcCode) && (
                <UIBox alignItems="center">
                  <SvgIcon
                    component={CheckCircleOutline}
                    style={{
                      fill: 'darkgreen',
                    }}
                  />
                  <Typography
                    font="light"
                    size="sm"
                    as="p"
                    style={{
                      marginLeft: 10,
                      color: '#888a8c',
                    }}
                  >
                    Reason: {reasonEpcNotFound(epcCode)}
                  </Typography>
                </UIBox>
              )}
              <UIAction
                label={t('reason')}
                icon={'edit'}
                disabled={disableActions}
                onClick={(): void => onReasonActionClick(epcCode)}
              />
              <UIAction
                label={t('print')}
                icon="print"
                disabled={
                  store?.printers === null ||
                  store?.printers?.length === 0 ||
                  notFoundEpcsWithReason?.includes(epcCode) ||
                  disableActions
                }
                onClick={(): void => onPrintActionClick(epcCode)}
              />
            </StyledActions>
          )}
          {isReasoned(epcCode) && (
            <UIBox alignSelf="flex-end">
              <UIAction
                label={t('removeReason')}
                icon="delete"
                disabled={disableActions}
                onClick={(): void => onReasonDeleteActionClick(epcCode)}
              />
            </UIBox>
          )}
        </UIBox>
      </>
    );
  };

  return (
    <>
      <StyledProductItemWrapper>
        <ProductItemWrapper
          $found={$found}
          $sale={$sale}
          $notFound={$notFound}
          $unexpected={$unexpected}
          $withAccordion={epcCodes && epcCodes.length > 1}
        >
          <ProductImage
            imageUrl={imageUrl}
            onClick={onClick}
            colorCode={colorCode}
            modelCode={modelCode}
            brandCode={brandCode}
            $v2={$v2}
            onClickAccordion={toggleAccordion}
            $isAccordionExpanded={$isAccordionExpanded}
          />
          <ProductDescription>
            <Typography size="lg" font="heavy" as="span">
              {brandCode} - {brandDescription} - {styleName}
            </Typography>
            <Typography size="md" font="book" as="p">
              {[upcCode, modelCode, commodity].filter(el => el).join(' - ')}
              {epcCodes && epcCodes.length === 1 && (
                <span className="epc-code"> - {epcCodes[0].epcCode}</span>
              )}
            </Typography>
          </ProductDescription>
          <ProductPrice
            price={price ? String(price) : undefined}
            currency={currency}
          >
            {$v2 && (
              <Typography font="light">
                {t('itemQuantity', {
                  itemQuantity: epcCodes?.length,
                })}
              </Typography>
            )}
            {epcCodes &&
              epcCodes.length === 1 &&
              renderActions(epcCodes[0].epcCode)}
          </ProductPrice>
        </ProductItemWrapper>
        {epcCodes && epcCodes.length > 1 && (
          <ProductAccordion
            items={`${epcCodes.length} ${t('items')}`}
            $v2={$v2}
            expanded={$isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            {epcCodes.map(
              (code: { epcCode: string; epcStatus: string }, i: number) => (
                <ProductAccordionItem
                  key={`${code.epcCode}-${i}-code`}
                  epcCode={code.epcCode}
                >
                  {renderActions(code.epcCode)}
                </ProductAccordionItem>
              )
            )}
          </ProductAccordion>
        )}
      </StyledProductItemWrapper>

      <NotificationBar
        cta={
          <UIButton
            label={t('cancel')}
            outlined
            disabled={false}
            onClick={onNotificationCancelClick}
          />
        }
        message={t('sale.removeArticle')}
        vertical="bottom"
        horizontal="center"
        open={isNotificationBarVisible}
        autoHideDuration={6000}
        onClose={onNotificationBarClose}
      />
    </>
  );
};

export default ScannedProductItemCycleCount;
