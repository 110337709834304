import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import { CheckoutService } from '@/api';
import type { RootState } from '@/app/rootReducer';

import type { ReceiveApiError as ApiError, ProductDeliveryList } from '@/api';
import { ConfirmCheckoutResponse, ProductDetailsItems } from '@/api/receive';

import { getUniqueListBy } from '@/utils/';
import getCurrentDate from '@/utils/getCurrentDate';

export interface SaleProductsSlice {
  products: ProductDetailsItems[];
  saleTags: string[];
  productsRemoved: (ProductDetailsItems & { index: number })[];
  epcsRemoved: {
    [key: string]: string[];
  };
  isLoading: boolean;
  error?: ApiError;
}

const initialState: SaleProductsSlice = {
  isLoading: false,
  products: [],
  productsRemoved: [],
  epcsRemoved: {},
  saleTags: [],
};

export const getSalesProducts = createAsyncThunk<
  ProductDeliveryList | undefined,
  { tags: string[] },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'salesProducts/getSalesProducts',
  async ({ tags: saleTags }, { getState, rejectWithValue }) => {
    const { currentStore } = getState();

    if (currentStore.store) {
      const { storeCode } = currentStore.store;
      const date = getCurrentDate();

      if (storeCode) {
        const saleTagsInStorage = sessionStorage.getItem('saleTags');

        if (saleTagsInStorage !== null || saleTags.length > 0) {
          try {
            return await CheckoutService.receiveshipSearchProductCheckoutItems({
              requestBody: {
                epcCodes: JSON.parse(saleTagsInStorage!) || saleTags,
                storeId: storeCode,
                date,
              },
            });
          } catch (error) {
            return rejectWithValue(error as ApiError);
          }
        }
      }
    }
  }
);

export const confirmSaleProductsCheckout = createAsyncThunk<
  ConfirmCheckoutResponse,
  NonNullable<
    Parameters<
      typeof CheckoutService.receiveshipConfirmCheckout
    >[number]['requestBody']
  >['epcCodes'],
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'salesProducts/confirmSaleProductsCheckout',
  async (epcCodes, { getState, rejectWithValue }) => {
    const { currentStore } = getState();

    if (currentStore.store) {
      const { storeCode } = currentStore.store;

      if (storeCode) {
        try {
          return await CheckoutService.receiveshipConfirmCheckout({
            requestBody: {
              epcCodes,
              storeId: storeCode,
            },
          });
        } catch (error) {
          return rejectWithValue(error as ApiError);
        }
      }
    }

    return {
      upcs: [],
    };
  }
);

const saleProductsSlice = createSlice({
  name: 'salesProducts',
  initialState,
  reducers: {
    removeSaleProductEpc: (
      state,
      {
        payload,
      }: PayloadAction<{
        upcCode: string;
        epcCode: string;
      }>
    ) => {
      const product = state.products.find(
        ({ upcCode }) => upcCode === payload.upcCode
      );
      const productIndex = state.products.findIndex(
        ({ upcCode }) => upcCode === payload.upcCode
      );

      const epcCodes =
        product?.epcCodes?.epcCodes?.filter(
          (code: string) => code !== payload.epcCode!
        ) || [];

      if (state.epcsRemoved[payload.upcCode]) {
        state.epcsRemoved[payload.upcCode].push(payload.epcCode);
      } else {
        state.epcsRemoved[payload.upcCode] = [payload.epcCode];
      }

      state.products[productIndex] = {
        ...product!,
        epcCodes: { epcCodes },
      };

      // state.saleTags = state.saleTags.filter(epc => epc !== payload.epcCode);

      sessionStorage.setItem(
        'saleTags',
        JSON.stringify(
          current(state)
            .products.map(({ epcCodes }) => epcCodes?.epcCodes?.flat())
            .flat()
        )
      );
    },
    removeSaleProduct: (state, { payload }: PayloadAction<string>) => {
      const lastProductRemoved = state.products.find(
        ({ upcCode }) => upcCode === payload
      );
      const lastProductRemovedIndex = state.products.findIndex(
        ({ upcCode }) => upcCode === payload
      );

      state.products.splice(lastProductRemovedIndex, 1);
      state.productsRemoved.push({
        ...lastProductRemoved!,
        index: lastProductRemovedIndex,
      });

      // lastProductRemoved?.epcCodes?.epcCodes?.forEach((removedEpc: string) => {
      //   state.saleTags = state.saleTags.filter(epc => epc !== removedEpc);
      // });

      sessionStorage.setItem(
        'saleTags',
        JSON.stringify(
          current(state)
            .products.map(({ epcCodes }) => epcCodes?.epcCodes?.flat())
            .flat()
        )
      );
    },
    readdDeletedProduct: (state, { payload }: PayloadAction<string>) => {
      if (state.productsRemoved.length > 0) {
        const deletedProduct = state.productsRemoved.find(
          ({ upcCode }) => upcCode === payload
        );

        if (deletedProduct) {
          state.productsRemoved = current(state).productsRemoved.filter(
            ({ upcCode }) => upcCode !== payload
          );

          if (
            state.products.findIndex(({ upcCode }) => upcCode === payload) < 0
          ) {
            state.products.splice(
              deletedProduct!.index,
              0,
              deletedProduct as ProductDetailsItems
            );

            // deletedProduct.epcCodes?.epcCodes?.forEach(code => {
            //   state.saleTags.push(code);
            // });

            sessionStorage.setItem(
              'saleTags',
              JSON.stringify(
                current(state)
                  .products.map(({ epcCodes }) => epcCodes?.epcCodes?.flat())
                  .flat()
              )
            );
          }
        }
      }
    },
    readdDeletedProductEpc: (
      state,
      { payload }: PayloadAction<{ upcCode: string; epcCode: string }>
    ) => {
      if (state.epcsRemoved[payload.upcCode].length > 0) {
        const productIndex = state.products.findIndex(
          ({ upcCode }) => upcCode === payload.upcCode
        );

        if (productIndex !== -1) {
          if (
            !state.products[productIndex].epcCodes?.epcCodes?.includes(
              payload.epcCode
            )
          ) {
            state.products[productIndex].epcCodes?.epcCodes?.push(
              payload.epcCode
            );
          }

          state.epcsRemoved[payload.upcCode] = state.epcsRemoved[
            payload.upcCode
          ].filter(epc => epc !== payload.epcCode);

          sessionStorage.setItem(
            'saleTags',
            JSON.stringify(
              current(state)
                .products.map(({ epcCodes }) => epcCodes?.epcCodes?.flat())
                .flat()
            )
          );
        }
      }
    },
    addSaleTags: (state, { payload }: PayloadAction<{ epc: string }[]>) => {
      const flatTags = payload.map(({ epc }): string => epc.toUpperCase());

      const concatTags = state.saleTags.concat(flatTags);

      const saleTags = [...new Set(concatTags)];

      state.saleTags = saleTags;

      sessionStorage.setItem('saleTags', JSON.stringify(saleTags));
    },
    deleteSaleTags: state => {
      sessionStorage.removeItem('saleTags');

      state = { ...state, saleTags: [] };
    },
    initSaleProducts: () => {
      sessionStorage.removeItem('saleTags');

      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSalesProducts.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSalesProducts.fulfilled, (state, { payload }) => {
        if (payload?.productDetailsItems) {
          const itemInState = state.products.map(item => ({ ...item }));
          const updatedProducts = itemInState.concat(
            payload.productDetailsItems
          );
          state.products = getUniqueListBy(updatedProducts, 'upcCode');
          state.isLoading = false;
          state.error = undefined;
        }
      })
      .addCase(getSalesProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(confirmSaleProductsCheckout.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(confirmSaleProductsCheckout.fulfilled, state => {
        state.isLoading = false;
        state.error = undefined;
      })
      .addCase(confirmSaleProductsCheckout.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as ApiError;
      });
  },
});

export const {
  removeSaleProduct,
  addSaleTags,
  deleteSaleTags,
  initSaleProducts,
  removeSaleProductEpc,
  readdDeletedProduct,
  readdDeletedProductEpc,
} = saleProductsSlice.actions;

export default saleProductsSlice.reducer;
