import { FC } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Typography } from '@/components/ui/Typography';
import { Button, ButtonProps } from '@material-ui/core';
import { UIBox } from '../Box';

export interface UIButtonWithIconProps extends ButtonProps {
  label: string;
  subLabel?: string;
  margin?: React.CSSProperties['margin'];
}

const StyledButton = styled(Button)<{
  disabled?: boolean;
  margin?: React.CSSProperties['margin'];
}>`
  align-items: center;
  padding: 0;
  width: auto;
  height: auto;
  text-transform: none;
  margin: ${({ margin }): string | number => (margin ? margin : '0')};

  && > span {
    color: #005192;
    font-size: 16px;

    &:nth-child(2) {
      display: none;
    }
  }

  svg {
    margin-right: 12px;
    fill: #005192;
  }

  &:hover {
    background: transparent;
  }

  ${({ disabled }): false | FlattenSimpleInterpolation | undefined =>
    disabled &&
    css`
      &&& {
        border: none;
        box-shadow: none;
        background: none;
        font-size: 16px;

        && > span {
          span {
            color: gray;
            font-size: 16px;
          }
        }
      }

      svg {
        fill: gray;
      }
    `};
`;

const StyledLabel = styled(Typography)`
  && {
    color: #005192;
  }
`;

const StyledSubLabel = styled(Typography)`
  margin-top: 8px;

  && {
    color: grey;
  }
`;

const ButtonWithIcon: FC<UIButtonWithIconProps> = ({
  label,
  subLabel,
  startIcon,
  endIcon,
  disabled,
  margin,
  ...rest
}) => {
  return (
    <StyledButton margin={margin} {...rest} disabled={disabled}>
      {startIcon && startIcon}
      <UIBox marginLeft={-1} flexDirection="column" alignItems="start">
        <StyledLabel font="medium">{label}</StyledLabel>
        {subLabel && <StyledSubLabel size="sm">{subLabel}</StyledSubLabel>}
      </UIBox>
      {endIcon && endIcon}
    </StyledButton>
  );
};

export default ButtonWithIcon;
