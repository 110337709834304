import { FC, memo, useEffect, useState } from 'react';
import {
  BootstrapInput,
  StyledInfoContainer,
  StyledErrorMessage,
  DisabledInput,
} from './style';
import { isEmpty } from '@/utils/validation';
import { TextFieldData } from './types';

export const GraficTextField: FC<TextFieldData> = memo(
  ({
    title,
    required,
    placeholder,
    name,
    defaultValue,
    validation,
    validationText,
    exportValue,
    updateMode = 'blur',
    notValidValues = [],
    notValidValueText,
    forceError,
    forceErrorText,
    disabled = false,
  }): JSX.Element => {
    const [isValid, setIsValid] = useState<boolean>(true);
    const [notValidValue, setNotValidValue] = useState<boolean>(false);
    const [valueIsEmpty, setvalueIsEmpty] = useState<boolean>(
      isEmpty(defaultValue)
    );
    const [currentVal, setCurrentVal] = useState<string>(defaultValue || '');
    const [isTouched, setIsTouched] = useState<boolean>(false);

    const updateHandler = (curVal: string): void => {
      setCurrentVal(curVal);
      if (!isTouched) {
        setIsTouched(true);
      }
      if (validation) {
        if (typeof validation === 'function') {
          setIsValid(isEmpty(curVal) || validation(curVal));
        } else {
          setIsValid(isEmpty(curVal) || validation.test(curVal));
        }
      }
      setNotValidValue(notValidValues.includes(curVal));
      setvalueIsEmpty(isEmpty(curVal));
    };

    const onChangeHanlder = (
      val: { target: { value: string } },
      updateMode: string
    ): void => {
      if (updateMode === 'change') {
        updateHandler(val?.target?.value);
      }
    };

    const onBlurHanlder = (
      val: { target: { value: string } },
      updateMode: string
    ): void => {
      if (updateMode === 'blur') {
        updateHandler(val?.target?.value);
      }
    };

    useEffect(() => {
      const valDetails = {
        name,
        currentVal,
        isValid,
        isEmpty: valueIsEmpty,
        notValidValue,
        isTouched,
      };
      if (exportValue) {
        exportValue?.(valDetails);
      }
    }, [
      currentVal,
      exportValue,
      isValid,
      name,
      valueIsEmpty,
      notValidValue,
      isTouched,
    ]);

    useEffect(() => {
      if (disabled) {
        onChangeHanlder({ target: { value: defaultValue || '' } }, updateMode);
      }
    }, [defaultValue, disabled, updateMode]);

    return (
      <StyledInfoContainer
        theme={(!isValid || notValidValue || forceError) && 'error'}
      >
        {title} {required && '*'}
        {disabled ? (
          <DisabledInput>{defaultValue}</DisabledInput>
        ) : (
          <BootstrapInput
            placeholder={placeholder}
            required={required}
            name={name}
            value={currentVal || defaultValue}
            onBlur={(val): void => onBlurHanlder(val, updateMode)}
            onChange={(val): void => onChangeHanlder(val, updateMode)}
            disabled={disabled}
          />
        )}
        {!isValid && <StyledErrorMessage>{validationText}</StyledErrorMessage>}
        {notValidValue && (
          <StyledErrorMessage>
            {notValidValueText || validationText}
          </StyledErrorMessage>
        )}
        {forceError && (
          <StyledErrorMessage>
            {forceErrorText || validationText}
          </StyledErrorMessage>
        )}
      </StyledInfoContainer>
    );
  }
);

GraficTextField.displayName = 'GraficTextField';

export default GraficTextField;
