import React, { FC, useEffect, useState, useCallback, useRef } from 'react';
import { useToggle } from 'react-use';
import { AppRoutes } from '@/app/routers';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

import ColumnDevice from './ColumnDevice';
import ColumnMode from './ColumnMode';

import { removeDeviceInUse } from '@/features/devices/devicesSlice';
import { deleteTags, startReader } from '@/features/devices/devicesSlice';
import { addEpcCodesNotFound } from '@/features/products/scannedProductsSlice';

import { StepLabel } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

import { DeviceStatus, EnumMode } from '@/types/enum';
import { useAppDispatch } from '@/app/store';
import { ScanDevice } from '@/types/device';

import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';
import { MQTTContext } from '@/context/MQTT';

import {
  StyledModal,
  StyledWrapper,
  StyledCloseIcon,
  StyledModalTitle,
  StyledContainer,
  StyledStepper,
  StyledStepContainer,
  StyledStep,
} from './styles';
import { useRecallContext } from '@/context/recallContext';

//#region - Types
export interface ModalScanDetailsProps {
  open: boolean;
  onClose: () => void;
  resetDeviceInUse?: boolean;
  startGetTags?: boolean;
  startGettingTags?: React.Dispatch<React.SetStateAction<boolean>>;
  nextPageURL?: string;
  defaultMode?: EnumMode;
  disabledModes?: EnumMode[];
  hideModes?: EnumMode[];
  setIsScanCompleted?: React.Dispatch<React.SetStateAction<boolean>>;
  isScanningAgain?: boolean;
  setScanAgainCounter?: React.Dispatch<React.SetStateAction<number>>;
  scanAgainCounter?: number;
  onStartReader?: () => void | Promise<void>;
  $v2?: boolean;
  tags?: {
    epc: string;
  }[];
}
//#endregion

export const ModalScanDetailsV2: FC<ModalScanDetailsProps> = ({
  startGettingTags,
  open,
  onClose,
  nextPageURL,
  defaultMode = EnumMode.INVENTORY,
  disabledModes = [],
  hideModes = [],
  setIsScanCompleted,
  isScanningAgain = false,
  resetDeviceInUse = false,
  setScanAgainCounter,
  onStartReader,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { username } = useSelector(state => state.user);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [mode, setMode] = useState<EnumMode>(defaultMode);
  const [somethingGoesWrong, setSomethingGoesWrong] = useState<boolean>(false);
  const timerRef = useRef<number>();

  const {
    startReaderHasError,
    deviceInUse,
    tags: readTags,
  } = useSelector(state => state.devices);
  const [selectedDevice, setSelectedDevice] = useState<ScanDevice | undefined>(
    deviceInUse
  );

  const subscribeDevice = useSubscribeDeviceStatus();
  const subscribeEPCS = useSubscribeEpcs(deviceInUse?.deviceId!);
  const { setDeviceIdOnMqttClient } = React.useContext(MQTTContext);

  const {
    notFound: cycleCountProductsNotFound,
    missing: cycleCountProductsMissing,
  } = useSelector(state => state.cycleCount.products);

  const { missingEpcs: cycleCountMissingEpcs, isMissingItemsCycleCount } =
    useSelector(state => state.cycleCount);

  const { epcs: receivingMissingEpcs } = useSelector(
    state => state.scannedProducts?.products.missing
  );

  const { recallItems } = useRecallContext();
  const { brands: recallBrands } = useSelector(state => state.recall);
  const { brands: inventoryBrands } = useSelector(
    state => state.inventory.inventoryReport
  );

  const { store } = useSelector(state => state.currentStore);

  const [
    isDeviceAlreadyInUseErrorVisible,
    setIsDeviceAlreadyInUseErrorVisible,
  ] = useToggle(false);

  const resetDevice = useCallback(async (): Promise<void> => {
    if (deviceInUse) {
      await dispatch(deleteTags());
      subscribeDevice.publish(deviceInUse?.deviceId!, undefined);
      setDeviceIdOnMqttClient(undefined);
      dispatch(removeDeviceInUse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mode, deviceInUse]);

  useEffect(() => {
    if (selectedDevice) {
      setActiveStep(1);
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (startReaderHasError) {
      setIsDeviceAlreadyInUseErrorVisible(true);
    }
  }, [startReaderHasError, setIsDeviceAlreadyInUseErrorVisible]);

  useEffect(() => {
    if (deviceInUse) {
      if (readTags && readTags.length > 0) {
        if (nextPageURL) {
          history.push(nextPageURL);
        }
      }
    }
  }, [readTags, deviceInUse, nextPageURL, history]);

  const handleMode = (): void =>
    mode === EnumMode.INVENTORY
      ? setMode(EnumMode.FIND)
      : setMode(EnumMode.INVENTORY);

  const handleClose = async (): Promise<void> => {
    if (onClose) {
      onClose();
      setActiveStep(0);

      if (
        pathname === AppRoutes.CYCLE_COUNT_MISSING_ITEMS ||
        pathname === AppRoutes.CYCLE_COUNT_FILTER_SUMMARY ||
        pathname === AppRoutes.RECEIVING_DELIVERIES ||
        pathname === AppRoutes.STORE_TRANSFER
      ) {
        clearTimeout(timerRef.current);
      }

      if (resetDeviceInUse) {
        await resetDevice();
      }
    }
  };

  const handleStep = (step: number): void => {
    setActiveStep(step);
  };

  const deviceTags = ():
    | {
        epc: string;
      }[] => {
    if (props.tags) {
      return props.tags;
    }

    if (pathname === AppRoutes.CYCLE_COUNT_MISSING_ITEMS) {
      return cycleCountMissingEpcs.map(epc => ({ epc }));
    } else if (
      pathname === AppRoutes.CYCLE_COUNT_FILTER_SUMMARY ||
      pathname === AppRoutes.SOH_ALIGNMENT_FILTER_SUMMARY ||
      pathname === AppRoutes.SOH_ALIGNMENT_FILTER_PRODUCTS
    ) {
      if (cycleCountProductsNotFound) {
        return cycleCountProductsNotFound
          .map(({ epcCodes }) => epcCodes)
          .flat()
          .map(({ epcCode }) => ({ epc: epcCode }));
      }
    } else if (pathname.indexOf(`${AppRoutes.RECALL}/`) > -1) {
      return recallBrands
        .flatMap(({ upcs }) => upcs)
        .flatMap(({ missing }) => missing!)
        .map(epc => ({ epc }));
    } else if (
      pathname.indexOf(`${AppRoutes.INVENTORY}-report/`) > -1 ||
      pathname.indexOf(`${AppRoutes.INVENTORY}-retry/`) > -1
    ) {
      const tags = inventoryBrands
        ?.flatMap(({ notFound }) => notFound || [])
        .flatMap(({ epcs }) => epcs || [])
        .map(epc => ({ epc }));

      if (tags) {
        return tags;
      }
    } else if (pathname === AppRoutes.RECEIVING_DELIVERIES) {
      return receivingMissingEpcs?.map(epc => ({ epc })) || [];
    }

    return [];
  };

  const onStartReaderClick = async (): Promise<void> => {
    if (selectedDevice) {
      if (username && store?.storeCode) {
        subscribeEPCS.subscribe();
        try {
          if (isScanningAgain) {
            if (mode === EnumMode.FIND) {
              if (props.tags) {
                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags: props.tags,
                  })
                ).unwrap();
              }

              if (pathname === AppRoutes.SCAN) {
                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags: receivingMissingEpcs?.map(epc => ({ epc })),
                  })
                ).unwrap();
              } else if (
                pathname === AppRoutes.CYCLE_COUNT_FILTER_PRODUCTS ||
                pathname === AppRoutes.SOH_ALIGNMENT_FILTER_PRODUCTS
              ) {
                let tags: {
                  epc: string;
                }[] = [];

                if (isMissingItemsCycleCount) {
                  const missingProductsEpcs = cycleCountProductsMissing
                    .flatMap(({ epcCodes }) => epcCodes)
                    .map(({ epcCode }) => ({ epc: epcCode }));

                  tags = missingProductsEpcs;
                } else {
                  const notFoudProductsEpcs = cycleCountProductsNotFound
                    .flatMap(({ epcCodes }) => epcCodes)
                    .map(({ epcCode }) => ({ epc: epcCode }));

                  await dispatch(
                    addEpcCodesNotFound(
                      cycleCountProductsNotFound
                        .flatMap(({ epcCodes }) => epcCodes)
                        .map(({ epcCode }) => epcCode)
                    )
                  );

                  tags = notFoudProductsEpcs;
                }

                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags,
                  })
                ).unwrap();
              } else if (pathname.indexOf(`${AppRoutes.RECALL}/`) > -1) {
                const { missing, found, error } = recallItems;

                const missingEpcs = missing
                  .flatMap(({ missing }) => missing!)
                  .map(epc => ({ epc }));

                const brandsEpcs = recallBrands
                  .flatMap(({ upcs }) => upcs)
                  .flatMap(({ missing }) => missing!)
                  .map(epc => ({ epc }));

                const tags =
                  missing.length === 0 &&
                  found.length === 0 &&
                  error.length === 0
                    ? brandsEpcs
                    : missingEpcs;

                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags,
                  })
                ).unwrap();
              } else if (
                pathname.indexOf(`${AppRoutes.INVENTORY}-report/`) > -1 ||
                pathname.indexOf(`${AppRoutes.INVENTORY}-retry/`) > -1
              ) {
                const tags = inventoryBrands
                  ?.flatMap(({ notFound }) => notFound || [])
                  .flatMap(({ epcs }) => epcs || [])
                  .map(epc => ({ epc }));

                await dispatch(
                  startReader({
                    deviceId: selectedDevice.deviceId!,
                    statusMode: mode,
                    statusDevice: DeviceStatus.BUSY,
                    storeId: store?.storeCode,
                    nameDevice: selectedDevice.nameDevice!,
                    userName: username,
                    tags,
                  })
                ).unwrap();
              }

              setScanAgainCounter?.(prevState => (prevState += 1));
            } else {
              await dispatch(
                startReader({
                  deviceId: selectedDevice.deviceId!,
                  statusMode: mode,
                  statusDevice: DeviceStatus.BUSY,
                  storeId: store?.storeCode,
                  nameDevice: selectedDevice.nameDevice!,
                  userName: username,
                })
              ).unwrap();
            }

            startGettingTags?.(true);
            setIsScanCompleted?.(false);
            onClose?.();
          } else {
            await dispatch(
              startReader({
                deviceId: selectedDevice.deviceId!,
                statusMode: mode,
                statusDevice: DeviceStatus.BUSY,
                storeId: store?.storeCode,
                nameDevice: selectedDevice.nameDevice!,
                userName: username,
                tags: mode === EnumMode.FIND ? deviceTags() : undefined,
              })
            ).unwrap();
          }

          if (
            pathname === AppRoutes.CYCLE_COUNT_MISSING_ITEMS ||
            pathname === AppRoutes.CYCLE_COUNT_FILTER_SUMMARY ||
            pathname === AppRoutes.RECEIVING_DELIVERIES ||
            pathname === AppRoutes.STORE_TRANSFER
          ) {
            const timer = setTimeout(() => {
              if (nextPageURL) {
                history.push(nextPageURL);
              }
            }, 5000);

            //@ts-ignore
            timerRef.current = timer;
          }

          // ON SALE ITEMS PAGE
          if (pathname === AppRoutes.SALE_ITEMS) {
            setIsScanCompleted?.(prevScanStatus => !prevScanStatus);
            onClose?.();
          }
          setDeviceIdOnMqttClient(selectedDevice.deviceId);
          subscribeDevice.publish(selectedDevice.deviceId, username);
          await onStartReader?.();
          return;
        } catch (error) {
          setScanAgainCounter?.(prevState => (prevState -= 1));
        }
      }
    }
  };

  return (
    <>
      <ErrorSnackbar
        open={somethingGoesWrong}
        setIsOpen={setSomethingGoesWrong}
        errorMessage={t('error.deviceCommunication')}
      />
      <ErrorSnackbar
        open={isDeviceAlreadyInUseErrorVisible}
        setIsOpen={setIsDeviceAlreadyInUseErrorVisible}
        errorMessage={startReaderHasError?.body?.msg}
      />
      <ErrorSnackbar
        open={isDeviceAlreadyInUseErrorVisible && !!startReaderHasError}
        setIsOpen={setIsDeviceAlreadyInUseErrorVisible}
        errorMessage={startReaderHasError?.body?.message}
        autoHideDuration={5000}
      />
      <StyledModal open={open} onClose={handleClose}>
        <StyledWrapper>
          <StyledCloseIcon onClick={handleClose}>
            <HighlightOffIcon />
          </StyledCloseIcon>
          <StyledModalTitle font="heavy" size="lg">
            {t('scanwizard.title')}
          </StyledModalTitle>
          <StyledContainer>
            <StyledStepper activeStep={activeStep} nonLinear>
              {[
                { title: t('scanwizard.1.device') },
                { title: t('scanwizard.2.mode') },
              ].map(({ title }, index) => (
                <StyledStep onClick={(): void => handleStep(index)} key={title}>
                  <StepLabel>{title}</StepLabel>
                </StyledStep>
              ))}
            </StyledStepper>
            <StyledStepContainer isActive={activeStep === 0}>
              <ColumnDevice
                scanContinue={isScanningAgain}
                selectedDevice={selectedDevice!}
                setDevice={setSelectedDevice}
                setActiveStep={setActiveStep}
              />
            </StyledStepContainer>
            <StyledStepContainer isActive={activeStep === 1}>
              <ColumnMode
                mode={mode}
                device={selectedDevice}
                isScanAgain={isScanningAgain}
                disabledModes={disabledModes}
                hideModes={hideModes}
                handleMode={handleMode}
                onStartReaderClick={onStartReaderClick}
              />
            </StyledStepContainer>
          </StyledContainer>
        </StyledWrapper>
      </StyledModal>
    </>
  );
};

ModalScanDetailsV2.displayName = 'ModalScanDetailsV2';

export default ModalScanDetailsV2;
