import React from 'react';
import styled from 'styled-components';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { TColorName } from '@/theme';
import { UIBox } from '../Box';
import { Typography } from '../Typography';

interface UIWarningProps {
  label: string;
}

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  fill: ${({ theme }): TColorName => theme.palette.colors.yellow};
  margin-right: 3px;
  width: 18px;
`;

const UIWarning: React.FC<UIWarningProps> = ({ label }) => {
  return (
    <UIBox display="flex" alignItems="center">
      <StyledErrorOutlineIcon />
      <Typography font="medium" as="p">
        {label}
      </Typography>
    </UIBox>
  );
};

export default UIWarning;
