import styled from 'styled-components';
import { UIBox } from '@/components/ui/Box';
import { UIAccordion } from '@/components/ui/Accordion';
import { UICheckbox } from '@/components/ui/Checkbox';
import { colors } from '@/theme';
import type { ProductValues } from '@/types/enum';

const color = (type?: ProductValues): string => {
  switch (type) {
    case 'UNEXPECTED':
      return colors.yellow;

    case 'NOT_FOUND':
      return colors.red;

    case 'FOUND':
      return colors.green;

    default:
      return colors.grey;
  }
};

export const StyledProductItem = styled(UIBox)`
  flex-direction: column;
  padding: 20px 0 !important;
  background: #fbfbfb;
  padding: 0;
  align-content: start;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
  }
`;

export const StyledProductContent = styled(UIBox)<{ $type?: ProductValues }>`
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 3fr 1fr auto;
  justify-content: space-between;

  &::before {
    content: '';
    border-radius: 100px;
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 45px;
    background: ${({ $type }): string => color($type)};
  }
`;

export const StyledAccordion = styled(UIAccordion)`
  &&& {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 -8px !important;
  }
`;

export const StyledCheckbox = styled(UICheckbox)`
  &&& {
    margin: 0 8px 0 -8px !important;
    width: 32px;
    height: 32px;
  }
`;

export const StyledContentInfo = styled(UIBox)`
  align-items: center;
  width: 100%;
`;

export const StyledCountWrapper = styled(UIBox)`
  align-items: center;
  flex-direction: column;
`;

export const StyledCount = styled(UIBox)`
  align-items: center;
`;

export const StyledDescription = styled(UIBox)`
  flex-direction: column;
  margin: 0 16px;
`;

export const StyledActionWrapper = styled(UIBox)`
  align-items: center;
  aling-self: flex-end;
  margin-left: 32px;
`;

export const StyledAccordionContent = styled(UIBox)<{ ml: string }>`
  flex-direction: column;
  padding: 18px 0 24px 32px;
  margin-left: ${({ ml }): string => ml};
`;

export const StyledAccordionItemContent = styled(UIBox)`
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 18px;
  }
`;

export const StyledEpcCode = styled('span')`
  color: ${colors.grey};

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
				display: block;
				margin-top: 8px;
		}`}
`;
