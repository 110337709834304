import React, { memo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  isRoleManager,
  isVirtualBuddy,
  isControlPanelAllowed,
  isSOHExtension,
  isDeliveryManager,
  isAdmin,
  isStoreMigratorAllowed,
} from '@/utils/user';

import { Grow, MenuItem, Popper, Paper, MenuList } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import StoreIcon from '@material-ui/icons/Store';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import { ReactComponent as DeliveryIcon } from '@/assets/icons/delivery.svg';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { Typography } from '@/components/ui/Typography';

import { UserData } from '@/types/user';

import { TColorValues } from '@/theme';

import { userLogout } from '@/features/user/userSlice';
import { useAppDispatch } from '@/app/store';
import { AppRoutes } from '@/app/routers';
import { useInitApplication } from '@/hooks/useInitApplication';

export interface UserInfoProps {
  user: UserData;
}

//#region - Styled Components

const WrapperMenuStyled = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Container = styled('button')`
  align-items: flex-end;
  border-radius: 8px;
  border: 1px solid ${({ theme }): TColorValues => theme.palette.common.black};
  display: inline-flex;
  flex-direction: column;
  height: 40px;
  min-height: 40px;
  justify-content: space-evenly;
  padding: 0 14px 0 30px;
  background: ${({ theme }): TColorValues => theme.palette.common.white};
  cursor: pointer;
`;

const StyledMenuContainer = styled(Popper)`
  z-index: 1;
  min-width: 156px;
  margin-left: -28px;

  && {
    position: absolute !important;
    right: 0 !important;
    top: 100% !important;
    left: initial !important;
    transform: unset !important;
  }
`;

const StyledMenuWrapper = styled(Grow)`
  border: 1px solid ${({ theme }): TColorValues => theme.palette.common.black};
`;

const StyledMenuItemIcon = styled(ListItemIcon)`
  && {
    min-width: auto;
    margin-left: 16px;
  }

  svg,
  path {
    fill: ${({ theme }): TColorValues => theme.palette.colors.black};
  }
`;

//#endregion

const UserInfo: React.FC<UserInfoProps> = memo(({ user }): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const initApplicationState = useInitApplication();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const isProcessEnabled = process.env.REACT_APP_DISABLE_POS === 'false';

  const onContainerClick = (): void => {
    setOpen(true);
  };

  const onClickAway = (e: React.MouseEvent<EventTarget>): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(e.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const onLogoutClick = async (): Promise<void> => {
    await initApplicationState();
    dispatch(userLogout());
  };

  const onSettingsClick = (): void => {
    setOpen(false);
    history.push(AppRoutes.SETTINGS);
  };

  const onAdminClick = (): void => {
    setOpen(false);
    history.push(AppRoutes.ADMIN);
  };

  const onRoleManagerClick = (): void => {
    setOpen(false);
    history.push(AppRoutes.MANAGE_ROLES);
  };

  const onVirtualBuddyClick = (): void => {
    setOpen(false);
    history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY);
  };

  const onSoHExtraction = (): void => {
    setOpen(false);
    history.push(AppRoutes.SOH_EXTACTION);
  };

  const onManageDelivery = (): void => {
    setOpen(false);
    history.push(AppRoutes.MANAGE_DELIVERY);
  };

  const onStoreMigratorClick = (): void => {
    setOpen(false);
    history.push(AppRoutes.STORE_MIGRATOR);
  };

  const onPosExceptionsClick = (): void => {
    setOpen(false);
    history.push(AppRoutes.POS_EXCEPTIONS);
  };

  return (
    <WrapperMenuStyled>
      {user && (
        <Container ref={anchorRef} onClick={onContainerClick}>
          <Typography size="lg">{user.username}</Typography>
          <Typography size="xs">{t(user.roles?.[0] || '')}</Typography>
        </Container>
      )}
      <StyledMenuContainer
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }): JSX.Element => (
          <StyledMenuWrapper
            {...TransitionProps}
            style={{ transformOrigin: 'top right' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList autoFocusItem={open}>
                  <MenuItem onClick={onSettingsClick}>
                    <ListItemText primary={`${t('user.menu_settings')}`} />
                    <StyledMenuItemIcon>
                      <SettingsIcon fontSize="small" />
                    </StyledMenuItemIcon>
                  </MenuItem>
                  {isStoreMigratorAllowed(user) && (
                    <MenuItem onClick={onStoreMigratorClick}>
                      <ListItemText primary={`${t('user.store_migrator')}`} />
                      <StyledMenuItemIcon>
                        <StoreIcon fontSize="small" />
                      </StyledMenuItemIcon>
                    </MenuItem>
                  )}
                  {isProcessEnabled && (
                    <>
                      {isAdmin(user) && (
                        <MenuItem onClick={onPosExceptionsClick}>
                          <ListItemText
                            primary={`${t('user.posExceptions')}`}
                          />
                          <StyledMenuItemIcon>
                            <ListAltOutlinedIcon fontSize="small" />
                          </StyledMenuItemIcon>
                        </MenuItem>
                      )}
                    </>
                  )}
                  {isControlPanelAllowed(user) && (
                    <MenuItem onClick={onAdminClick}>
                      <ListItemText primary={`${t('user.control_panel')}`} />
                      <StyledMenuItemIcon>
                        <CreditCardIcon fontSize="small" />
                      </StyledMenuItemIcon>
                    </MenuItem>
                  )}
                  {isRoleManager(user) && (
                    <MenuItem onClick={onRoleManagerClick}>
                      <ListItemText primary={`${t('menu.manageRoles')}`} />
                      <StyledMenuItemIcon>
                        <EditIcon fontSize="small" />
                      </StyledMenuItemIcon>
                    </MenuItem>
                  )}
                  {isVirtualBuddy(user) && (
                    <MenuItem onClick={onVirtualBuddyClick}>
                      <ListItemText primary={`${t('menu.virtual_buddy')}`} />
                      <StyledMenuItemIcon>
                        <AccountCircleSharpIcon fontSize="small" />
                      </StyledMenuItemIcon>
                    </MenuItem>
                  )}
                  {isSOHExtension(user) && (
                    <MenuItem onClick={onSoHExtraction}>
                      <ListItemText primary={`${t('menu.SoHExtraction')}`} />
                      <StyledMenuItemIcon>
                        <GetAppIcon fontSize="small" />
                      </StyledMenuItemIcon>
                    </MenuItem>
                  )}
                  {isDeliveryManager(user) && (
                    <MenuItem onClick={onManageDelivery}>
                      <ListItemText primary={`${t('menu.manageDelivery')}`} />
                      <StyledMenuItemIcon>
                        <DeliveryIcon color="black" />
                      </StyledMenuItemIcon>
                    </MenuItem>
                  )}
                  <MenuItem onClick={onLogoutClick}>
                    <ListItemText primary={`${t('user.menu_logout')}`} />
                    <StyledMenuItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </StyledMenuItemIcon>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </StyledMenuWrapper>
        )}
      </StyledMenuContainer>
    </WrapperMenuStyled>
  );
});

UserInfo.displayName = 'UserInfo';

export default UserInfo;
