// @ts-nocheck
import * as React from 'react';
import styled, { css } from 'styled-components';
import { palette } from '@/theme';
import { useUpdateEffect } from 'react-use';
import { UILoader } from '@/components/ui/Loader';

const StyledItem = css<{ currentPage?: boolean }>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    text-align: center;
    list-style: none;
    color: ${({ currentPage }): string =>
      currentPage
        ? `${palette.primary.main} !important`
        : `${palette.colors.text}`};
    background-color: #fff;
    border: ${({ currentPage }): string =>
      currentPage ? `1px solid ${palette.primary.main}` : '1px solid #d9d9d9'};
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover {
    color: ${palette.primary.main};
    border-color: ${palette.primary.main} !important;
  }
`;

const StyledPaginationWrapper = styled('div')`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const StyledPaginationList = styled('ul')`
  padding-left: 0;
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    float: left;
    list-style: none;
    margin: 0 4px;
    cursor: pointer;
  }
`;

const StyledPaginationItem = styled('li')<{ currentPage?: boolean }>`
  ${StyledItem}
`;

const StyledPaginationJumpTo = styled('li')`
  ${StyledItem}

  border: 0 !important;
  font-size: 12px;

  span {
    opacity: 1;
    transition: all 200ms ease;
  }
`;

const StyledPaginationIcon = styled('li')<{ disabled?: boolean }>`
  ${StyledItem}

  && {
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
    color: ${({ disabled }): string => (disabled ? '#e8e6e6' : 'black')};
  }
`;

interface Props {
  data: Array<T>;
  pageSize: number;
  renderItem: Function;
  infiniteScroll?: boolean;
  setItemsInPage?: React.Dispatch<React.SetStateAction<T[]>>;
  setCurrPage?: React.Dispatch<React.SetStateAction<number>>;
  currPage?: number;
  noItems?: JSX.Element;
}

interface DataPaginate {
  totalPage: number;
  arrayList: Array<T>;
  arrayAll: Array<T>;
  nextPage: number;
  prePage: number;
  pageCurrent: number;
}

const PaginationList: React.FC<Props> = React.memo(props => {
  const { data, pageSize, currPage, setCurrPage }: Props = props,
    [countJump, setCountJump] = React.useState<number>(1),
    [arrayPage, setArrayList] = React.useState<Array<T>>([]),
    [arrItemPage, setArrItemPage] = React.useState<Array<T>>([]),
    [totalPage, setTotalPage] = React.useState<number>(1),
    [flagEnd, setFlagEnd] = React.useState<boolean>(false);
  let [currentPage, setCurrentPage] = React.useState<number>(currPage || 1);
  const [moreLoading, setMoreLoading] = React.useState<boolean>(false);
  const observer = React.useRef<null | IntersectionObserver>(null);

  const loader = React.useRef(null);

  const jump: number = pageSize;

  const handleObserver = (entities): void => {
    const target = entities[0];
    if (target.isIntersecting) {
      setMoreLoading(true);
      _onNextPage();
    } else {
      setMoreLoading(false);
    }
  };

  React.useEffect(() => {
    const { arrayList, totalPage, pageCurrent } = handlePaginate(
      data,
      currPage || 1,
      pageSize ? pageSize : 0
    );
    totalPage > pageSize
      ? setArrItemPage([...Array.from(Array(pageSize).keys())])
      : setArrItemPage([...Array.from(Array(totalPage).keys())]);
    setArrayList(arrayList);
    setTotalPage(totalPage);
    setCurrentPage(pageCurrent);

    if (currPage) {
      setCurrPage?.(pageCurrent);
    }

    return (): void => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [pageSize, currPage, data, setCurrPage]);

  useUpdateEffect(() => {
    setCountJump(1);
  }, [totalPage]);

  useUpdateEffect(() => {
    if (totalPage > 1) {
      const options = {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
      };
      const ob = (observer.current = new IntersectionObserver(
        handleObserver,
        options
      ));
      if (loader.current) {
        ob.observe(loader.current);
      }
    }
  }, [totalPage]);

  const handlePaginate = (
    data: Array<T>,
    page: number,
    pageSize: number
  ): T => {
    const result: DataPaginate = {
      totalPage: 1,
      arrayList: [],
      arrayAll: data,
      nextPage: 1,
      prePage: 1,
      pageCurrent: currPage || 1,
    };
    if (data.length > 0) {
      const totalPage: number = Math.ceil(data.length / pageSize);
      let pageCurrent: number =
        !!page && page
          ? page !== 0
            ? page <= totalPage
              ? page
              : totalPage
            : 1
          : 1;
      const nextPage: number =
        pageCurrent < totalPage ? pageCurrent + 1 : pageCurrent;
      const prePage: number = pageCurrent > 1 ? pageCurrent - 1 : pageCurrent;
      const arrayList: Array<T> =
        pageSize !== 0
          ? data.slice((page - 1) * pageSize, page * pageSize)
          : data;
      result['totalPage'] = totalPage;
      result['arrayList'] = arrayList;
      result['nextPage'] = nextPage;
      result['prePage'] = prePage;
      result['pageCurrent'] = pageCurrent;

      props.setItemsInPage?.(arrayList);
    }

    return result;
  };
  const calNumberPage = (countJump: number, index: number): number => {
    let page: number = 1;
    if (countJump === 1 && !flagEnd) {
      page = index;
    } else {
      if (totalPage - jump < pageSize) {
        page = totalPage - (jump - index);
      } else {
        page =
          jump * countJump > totalPage
            ? totalPage - (jump - index)
            : jump * countJump - (jump - index);
      }
    }
    return page;
  };
  const setThemeItemSelected = (selectedPage: number | string | null): void => {
    const liesNode: NodeListOf<HTMLLIElement> =
        document.querySelectorAll('.k-pagination-item'),
      lies: HTMLLIElement[] = Array.from(liesNode);
    for (const li of lies) {
      li.classList.remove('styles.kPaginationActive');
      const thisPage: number | string | null = li.getAttribute('title');
      if (thisPage) {
        if (thisPage === selectedPage) {
          li.classList.add('styles.kPaginationActive');
        }
      }
    }
  };

  const _onChangePage = (page: number, type?: string): void => {
    const { arrayList } = handlePaginate(data, page, pageSize ? pageSize : 0);
    setCurrentPage(page);
    if (props.infiniteScroll) {
      setArrayList(arrayPage => arrayPage.concat(arrayList));
    } else {
      setArrayList(arrayList);
    }
    setThemeItemSelected(page);
    if (type) {
      setCountJump(type === 'first' ? 1 : Math.ceil(totalPage / jump));
    }
  };

  const _showListPage = (totalPage: number): Array<T> => {
    let firstPage: number = 1,
      lastPage: number = totalPage,
      elmLastPage: React.ReactChild = (
        <StyledPaginationItem
          key={lastPage}
          onClick={(): void => _onChangePage(lastPage, 'last')}
        >
          {lastPage}
        </StyledPaginationItem>
      ),
      elmFirstPage: React.ReactChild = (
        <StyledPaginationItem
          key={firstPage}
          onClick={(): void => _onChangePage(firstPage, 'first')}
          currentPage={currentPage === 1}
        >
          {firstPage}
        </StyledPaginationItem>
      ),
      elmIconJumpNext = null,
      elmIconJumpPrev = null;

    const setDefaultPageCurrent = (state: boolean): void => {
      if (!state) {
        const pageDefaultNext = totalPage - (jump - 1);
        setCurrentPage(pageDefaultNext);
        _onChangePage(pageDefaultNext);
      } else {
        let pageDefaultNext = jump * countJump - (jump - 1);
        pageDefaultNext =
          pageDefaultNext === totalPage ? pageDefaultNext - 4 : pageDefaultNext;
        setCurrentPage(pageDefaultNext);
        _onChangePage(pageDefaultNext);
      }
    };

    const _onGetJumpNext = (): void => {
      let isNextFull = true;
      if (arrItemPage.length + jump > totalPage) {
        isNextFull = false;
        setFlagEnd(true);
      } else {
        setCountJump(countJump + 1);
      }
      setArrItemPage([...Array.from(Array(jump).keys())]);
      setDefaultPageCurrent(isNextFull);
    };

    const _onGetJumpPrev = (): void => {
      let isNextFull = true;
      if (arrItemPage.length + jump > totalPage) {
        //jump pageSize next page larger total page
        isNextFull = false;
        setFlagEnd(false);
      } else {
        setCountJump(countJump - 1);
      }
      setArrItemPage([...Array.from(Array(jump).keys())]);
      setDefaultPageCurrent(isNextFull);
    };
    if (totalPage > jump) {
      //set icon jump next
      elmIconJumpNext = (
        <StyledPaginationJumpTo key={'jump_next'} onClick={_onGetJumpNext}>
          <span>{'•••'}</span>
        </StyledPaginationJumpTo>
      );
      // set icon jump prev
      elmIconJumpPrev = (
        <StyledPaginationJumpTo key={'jump_prev'} onClick={_onGetJumpPrev}>
          <span>{'•••'}</span>
        </StyledPaginationJumpTo>
      );
    }
    const listPage = arrItemPage.map((_item, index) => {
      const pagePos: number = calNumberPage(countJump, ++index);
      return (
        <StyledPaginationItem
          key={pagePos}
          onClick={(): void => {
            _onChangePage(pagePos);

            if (currPage) {
              setCurrPage(pagePos);
            }
          }}
          currentPage={currentPage === pagePos}
        >
          {pagePos}
        </StyledPaginationItem>
      );
    });

    if (totalPage > pageSize) {
      const elmJumpNextPage = (
        <React.Fragment key={'wrap_jump_next'}>
          {countJump * jump < totalPage ? (
            <>
              {elmIconJumpNext}
              {elmLastPage}
            </>
          ) : null}
        </React.Fragment>
      );
      const elmJumpPrevPage = (
        <React.Fragment key={'wrap_jump_prev'}>
          {elmFirstPage}
          {elmIconJumpPrev}
        </React.Fragment>
      );
      if (countJump > 1 || flagEnd) listPage.unshift(elmJumpPrevPage);
      if (!flagEnd) listPage.push(elmJumpNextPage);
    }
    return listPage;
  };

  const _onPrevPage = (): void => {
    if (currentPage !== 1) {
      setCurrentPage(--currentPage);
      _onChangePage(currentPage);
    }
    if (totalPage % jump === 0) {
      if (currentPage % jump === 0) {
        setCountJump(countJump - 1);
      }
    } else {
      if (countJump === Math.ceil(totalPage / jump)) {
        if (totalPage - currentPage === jump) {
          setCountJump(countJump - 1);
        }
      } else {
        if (currentPage % jump === 0) {
          setCountJump(countJump - 1);
        }
      }
    }

    if (currPage) {
      setCurrPage(prevState => prevState - 1);
    }
  };
  const _onNextPage = (): void => {
    if (currentPage !== totalPage) {
      setCurrentPage(++currentPage);
      _onChangePage(currentPage);
    }
    if (currentPage % jump === 1) {
      setCountJump(countJump + 1);
    }

    if (currPage) {
      setCurrPage(prevState => prevState + 1);
    }
  };

  const showListPaginate = (): React.ReactChild => {
    return pageSize && pageSize !== 0 && totalPage > 1 ? (
      <StyledPaginationList>
        <StyledPaginationIcon
          disabled={currentPage === 1}
          onClick={_onPrevPage}
        >
          <span>&lt;</span>
        </StyledPaginationIcon>
        {_showListPage(totalPage)}
        <StyledPaginationIcon
          disabled={currentPage === totalPage}
          onClick={_onNextPage}
        >
          <span>&gt;</span>
        </StyledPaginationIcon>
      </StyledPaginationList>
    ) : (
      <div></div>
    );
  };
  return (
    <StyledPaginationWrapper>
      <div className={'kPaginationListItem'}>
        {arrayPage.length > 0 ? (
          <>{arrayPage.map((item, key) => props.renderItem(item, key))}</>
        ) : (
          <>{props.noItems}</>
        )}
      </div>
      {!props.infiniteScroll && showListPaginate()}
      {props.infiniteScroll && totalPage > 1 && currentPage < totalPage && (
        <div style={{ textAlign: 'center' }} ref={loader}>
          {moreLoading && <UILoader />}
        </div>
      )}
    </StyledPaginationWrapper>
  );
});

PaginationList.displayName = 'PaginationList';

export default PaginationList;
