import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getDeliveryList } from '@/features/deliveries/deliveriesSlice';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { UIButton } from '@/components/ui/Button';

import * as S from './style';
import { ApiError, ProcessService } from '@/api/receive';
import { useSelector } from '@/hooks/useSelector';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAsync } from 'react-use';
import useHandleProcess from '@/hooks/useHandleProcess';
import useExpectionsNotification from '@/hooks/useExpectionsNotification';

export const PageSale: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleProcess = useHandleProcess();
  const sale = useExpectionsNotification('SLEX');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [onGoingActivities, setOnGoingActivities] =
    React.useState<boolean>(false);
  const [genericError, setGenericError] = React.useState<boolean>(false);
  const [processLockedBy, setProcessLockedBy] = React.useState<string>('');
  const processLocked = useSelector(state => state.pos.processLocked);
  const isProcessEnabled = process.env.REACT_APP_DISABLE_POS === 'false';
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  useEffect(() => {
    dispatch(getDeliveryList());
  }, [dispatch]);

  useAsync(async () => {
    if (processLocked) {
      await handleProcess({ process: 'SLEX', isLocked: false });
    }
  }, [dispatch, processLocked]);

  const handleSaleExceptionsClick = async (): Promise<void> => {
    try {
      setLoading(true);
      await handleProcess({ process: 'SLEX', isLocked: true });
      history.push(AppRoutes.SALE_EXCEPTIONS);
    } catch (e) {
      const error = e as ApiError;

      setLoading(false);

      if (error.status === 403) {
        setOnGoingActivities(true);

        const salesExceptionProcess =
          await ProcessService.processReadProcessStatus({
            storeCode,
            process: 'SLEX',
            filterByStoreCode: true,
          });

        if (salesExceptionProcess && salesExceptionProcess[0].isLocked) {
          setProcessLockedBy(salesExceptionProcess[0].lockedBy);
        }
      } else {
        setGenericError(true);
      }
    }
  };

  return (
    <>
      <Modal
        iconType="ERROR"
        $minWidth="420px"
        $minHeight="230px"
        title={t('tryAgain') + '!'}
        message={t('onGoingActivitiesMessage', { user: processLockedBy })}
        open={onGoingActivities}
        onClose={(): void => {
          setOnGoingActivities(false);
          setProcessLockedBy('');
        }}
      />
      <UIMacroFlowMenu title={t('menu.title')}>
        <S.Link to={AppRoutes.SALE_ITEMS}>
          <UIButton label={t('menu.checkoutLight')} outlined={false} />
        </S.Link>
        {isProcessEnabled && (
          <S.Link to={AppRoutes.SALE}>
            <UIButton
              label={t('menu.salesExceptions')}
              loading={loading}
              disabled={loading}
              outlined={false}
              onClick={handleSaleExceptionsClick}
              startIcon={
                <>{sale.exceptionsOccurred && <sale.ExceptionsIcon />}</>
              }
            />
          </S.Link>
        )}
      </UIMacroFlowMenu>
      <ErrorSnackbar
        open={genericError}
        setIsOpen={(): void => setGenericError(false)}
        errorMessage={t('error.openSalesExceptions')}
      />
    </>
  );
};

export default PageSale;
