import React from 'react';
import { ProcessService } from '@/api/receive';
import { useSelector } from './useSelector';

export type Process =
  | 'CRDA' // In store damages
  | 'CODA' // Confirm damages
  | 'BTST' // Back to stock
  | 'REOR' // Recall order
  | 'REPR' // Repricing
  | 'INVT' // Inventory
  | 'INVP'
  | 'CSRT' // Customer returns
  | 'SLEX' // Sales exception
  | 'RTPS' // Return pos
  | 'CPOS' // Confirm PoS Checkout
  | 'MSUP' // Manual store upload
  | 'RSUP' // Reader store upload
  | 'DSUP' // Delete store upload
  | 'VSUP' // Vas store upload
  | 'SOHA' // Soh on hand alignment
  | 'CYCO'; // CycleCount

type HandleProcessBody = {
  process: Process;
  isLocked: boolean;
};

type ReturnType = (body: HandleProcessBody) => Promise<void>;

const useHandleProcess = (): ReturnType => {
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const handleProcess = React.useCallback(
    async ({ process, isLocked }: HandleProcessBody) => {
      await ProcessService.processHandlingProcess({
        requestBody: {
          isLocked,
          process,
          storeCode,
        },
      });
    },
    [storeCode]
  );

  return handleProcess;
};

export default useHandleProcess;
