import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/ui/Typography';
import { UIAction } from '@/components/ui/Action';

import { UIBox } from '@/components/ui/Box';
import { StyledTablesRow } from './styles';
import { Store } from '@/api/receive';
import { useHistory } from 'react-router';
import { makeUrl } from '@/utils/links';
import { UrlUpdateStore } from '@/pages/PageStoreDetails/types';
import { AppRoutes } from '@/app/routers';

interface ProfilingStoreRowProps {}

const StyledActions = styled(UIBox)`
  margin-left: auto;

  & > div {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const ProfilingStoreRow: React.FC<Store & ProfilingStoreRowProps> = ({
  ...store
}) => {
  const { t } = useTranslation();
  const { storeCode, salesOrganization, storeAddress, storeStatus } = store;
  const history = useHistory();

  return (
    <StyledTablesRow>
      <Typography>{salesOrganization}</Typography>
      <Typography>{storeCode}</Typography>
      <Typography>{storeAddress}</Typography>
      <Typography>{storeStatus}</Typography>
      <StyledActions>
        <UIAction
          label={t('edit')}
          icon="edit"
          onClick={(): void =>
            history.push(
              makeUrl<UrlUpdateStore>(AppRoutes.STORE_DETAILS, {
                store: storeCode,
              })
            )
          }
        />
      </StyledActions>
    </StyledTablesRow>
  );
};

export default ProfilingStoreRow;
