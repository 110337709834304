import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { RecallStatus, useRecallContext } from '@/context/recallContext';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TodayIcon from '@material-ui/icons/Today';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { DateTime } from 'luxon';
import { UIBox } from '@/components/ui/Box';

import { RecallResponse } from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { StyledIconWrapper, StyledListItem, StyledChip } from './style';
import { Typography } from '@/components/ui/Typography';
import { colors } from '@/theme';
import { changeRecallStatus } from '@/features/recall/recallSlice';
import { useAppDispatch } from '@/app/store';

const RecallOrderListItem: React.FC<RecallResponse> = ({
  idRecall,
  deliveryNr,
  recallStatus,
  numberItems,
  startDate,
  endDate,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { setRecallStatus } = useRecallContext();

  const itemRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<number>();
  const countRef = useRef<number>(0);

  const renderDate = (date: string): string => {
    const dateFormat = date.replaceAll('/', '-');

    return DateTime.fromFormat(dateFormat, 'dd-MM-yyyy').toFormat(
      'yyyy LLLL dd',
      {
        locale: i18n.language,
      }
    );
  };

  const renderIcon = (status: string): JSX.Element => {
    switch (status) {
      case 'Ongoing':
        return (
          <StyledIconWrapper background={colors.primary}>
            <MoreHorizIcon htmlColor="#fff" />
          </StyledIconWrapper>
        );

      case 'Failed':
        return <ErrorIcon htmlColor="#F6BB3B" />;

      default:
        return <CheckCircleIcon htmlColor={colors.green} />;
    }
  };

  const recallItemClickHandler = (
    idRecall: string,
    status: RecallStatus
  ): void => {
    if (status === 'Ongoing') return;

    setRecallStatus(status);
    history.push(`${AppRoutes.RECALL}/${idRecall}`);
  };

  const handleTripleClick = async (): Promise<void> => {
    countRef.current = countRef.current + 1;

    const timerIsPresent = timerRef.current;

    if (
      timerIsPresent &&
      countRef.current === 3 &&
      recallStatus === 'Ongoing'
    ) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
      countRef.current = 0;

      if (window.confirm(`Reset recall ${idRecall}?`)) {
        await dispatch(
          changeRecallStatus({
            id: idRecall,
            status: 'New',
          })
        );
      }
    }

    if (!timerIsPresent) {
      const timer = setTimeout(() => {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
        countRef.current = 0;
      }, 1000);

      //@ts-ignore
      timerRef.current = timer;
    }
  };

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.addEventListener('click', handleTripleClick);
    }
  });

  return (
    <StyledListItem
      button
      disabled={recallStatus === 'Ongoing' || recallStatus === 'Completed'}
      ref={itemRef}
      key={idRecall}
      onClick={(): void =>
        recallItemClickHandler(idRecall, recallStatus as RecallStatus)
      }
    >
      <Typography size="lg" font="heavy">
        {idRecall}
        {deliveryNr && ` - ${deliveryNr}`}
      </Typography>
      <UIBox alignItems="center" marginLeft={5}>
        <UIBox alignItems="center">
          <TodayIcon htmlColor={colors.primary} />
          <Typography margin="0 0 0 8px">
            <strong>{t('startDate')}:</strong> {renderDate(startDate!)} -{' '}
            <strong>{t('endDate')}:</strong> {renderDate(endDate!)}
          </Typography>
        </UIBox>
      </UIBox>
      <UIBox flexDirection="column" justifyContent="flex-end" marginLeft="auto">
        {recallStatus === 'New' ? (
          <UIBox alignItems="center">
            <StyledChip>
              {t('itemsFound', { itemsFound: numberItems })}
            </StyledChip>
            <StyledChip marginLeft={8} background={colors.primary}>
              {t('new')}
            </StyledChip>
          </UIBox>
        ) : (
          <>
            <UIBox alignItems="center" alignSelf="flex-end">
              <Typography>{t(recallStatus!)}</Typography>
              {renderIcon(recallStatus!)}
            </UIBox>
            {recallStatus === 'Failed' && (
              <Typography color="grey" size="sm" font="book" margin="6px 0 0">
                {t('needsReprocessed')}
              </Typography>
            )}
          </>
        )}
      </UIBox>
    </StyledListItem>
  );
};

export default RecallOrderListItem;
