import { FC } from 'react';
import styled from 'styled-components';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

interface ProductAccordionItemProps {
  className?: string;
  epcCode: string;
  timestamp?: string;
}

const StyledItemWrapper = styled(UIBox)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledActionWrapper = styled(UIBox)`
  display: inline-flex;
  align-items: center;
  gap: 20px;
  margin-right: -95px;
`;

const TimestampWrapper = styled(Typography)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ProductAccordionItem: FC<ProductAccordionItemProps> = ({
  epcCode,
  className,
  children,
  timestamp,
}) => {
  return (
    <StyledItemWrapper className={className}>
      {epcCode}
      {timestamp && (
        <TimestampWrapper as="div">
          <AccessTimeIcon />
          <span>{timestamp}</span>
        </TimestampWrapper>
      )}
      <StyledActionWrapper>{children}</StyledActionWrapper>
    </StyledItemWrapper>
  );
};

export default ProductAccordionItem;
