import { PrintConfirm } from '@/api'

export enum DeviceStatus {
	ONLINE = 'online',
	OFFLINE = 'offline',
	BUSY = 'busy',
}

export enum EnumMode {
	INVENTORY = 'inventory',
	FIND = 'find',
	SCANNING = 'scanning',
	RADAR = 'radar',
	WRITE = 'write',
	NO_MODE = 'no mode',
}

export enum ModalReasonType {
	UNEXPECTED = 'UNEXPECTED',
	NOTFOUND = 'NOTFOUND',
}

export type PrintReasonCodeStatus = PrintConfirm['reason']

export enum PrintMode {
	New = 'new',
	Update = 'update',
}

export enum UserPermissions {
	ADMIN = 'AM',
	STOREMIGRATOR = 'SM',
	CYCLECOUNT = 'CC',
	RECEIVING = 'RCC',
	PRINTTAG = 'PN',
	REPRICING = 'RP',
	SALE = 'SA',
	STOCKSUMMARY = 'SS',
	STORE2STORE = 'TOC',
	MANAGESTORES = 'SMG',
	MANAGEUSERS = 'UMG',
	MANAGEREASON = 'RCM',
	UPLOADREASON = 'URC',
	CREATEDAMAGE = 'CD',
	VALIDATEDAMAGE = 'VD',
	SOHEXTENSION = 'SOHE',
	CUSTOMERRETURNS = 'CR',
	INVENTORY = 'INV',
	FOLLOW_REMOTE_INVENTORY = 'FRI',
	CONFIRM_INVENTORY = 'CINV',
	FOLLOW_CONFIRM_INVENTORY = 'FCRI',
	BEGIN_INVENTORY = 'BINV',
	HIDE_INVENTORY = 'HINV',
	RECALL = 'RLL',
	DELIVERY_MANAGER = 'MADE',
	STOCK_SUMMARY_PRINT_ENABLED = 'SSPE',
	LOADER_SUPER_USER = "RLSU",
	LOADER_OPERATOR = "RLOP",
	MANAGE_SALE_EXCEPTIONS = 'MSEX',
	MANUAL_LOADER = 'MANL',
	DELETE_EPC = 'DSOH',
	SOHA_ADJUSTMENT_SUPER_USER = 'CCAS',
	SOHA_ADJUSTMENT_USER = 'CCAU',
	APP_REPORT_DOWNLOADER = "SOHA",
	SAP_REPORT_DOWNLOADER = "SOHS"
}

export enum STOREMIGRATORSTATUS {
	WAITING = 'Waiting to be uploaded',
	LOADING = 'Loading',
	ERROR = 'Error',
	COMPLETED = 'Completed',
}

export enum InventoryStatus {
	SCHEDULED = 'Scheduled',
	ON_GOING = 'Ongoing',
	WAITING_APPLICATION = 'Waiting for Application',
	WAITING_VALIDATION = 'Waiting for Validation',
	VALIDATED = 'Validated',
	REFUSED = 'Refused',
	HIDDEN = 'Hidden',
}

export enum ItemStatus {
	FOUND = 'found',
	NOT_FOUND = 'notFound',
	EXPECTED = 'expected',
	UNEXPECTED = 'unexpected',
}

export type InventoyStatusValues = `${InventoryStatus}`;
export type ItemStatusValues = `${ItemStatus}`;

export enum NOTIFICATION {
	RECALL = "REOR",
	REPRICING = "REPR",
	INVENTORY = "INVT",
	INVENTORYP = "INVP",
}

export enum EPC_STATUS {
	IN_STOCK = 'In stock',
	SOLD = 'Sold',
	MISSING = 'Missing',
	STOLEN = 'Stolen',
	IN_TRANSIT = 'In transit',
	QUALITY_INSPECTION = 'Quality inspection',
	RECALLED = 'Recalled',
	DAMAGED = 'Damaged',
	CHECKOUT_ERROR = 'Missing - Check Out Error'
}

export type EpcStatusValues = `${EPC_STATUS}`;

export enum Product {
	UNEXPECTED = 'UNEXPECTED',
	FOUND = 'FOUND',
	NOT_FOUND = 'NOT_FOUND',
	SOLD = 'SOLD',
	IN_TRANSIT = 'IN_TRANSIT',
	QUALITY_INSPECTION = 'QUALITY_INSPECTION',
	RECALLED = 'RECALLED',
	DAMAGED = 'DAMAGED',
	CHECKOUT_ERROR = 'MISSING_CHECKOUT_ERROR'
}

export type ProductValues = `${Product}`;

