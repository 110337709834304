import { FC, memo, useEffect, useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import TimerIcon from '@material-ui/icons/Timer';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { UIButton } from '@/components/ui/Button';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';
import { DateTime } from 'luxon';
import { colors } from '@/theme';

export interface TimerProps {
  $v2?: boolean;
  minutes: string;
  seconds: string;
  sticky?: boolean;
  inPause?: boolean;
  setPause?: (arg: boolean) => void;
}

//#region - Styled Components
const StyledTimer = styled(UIBox)<{ $sticky?: boolean }>`
  && {
    ${({ $sticky }): false | FlattenSimpleInterpolation | undefined =>
      $sticky &&
      css`
        padding: 8px !important;

        h1,
        span {
          color: #fff;
        }
      `}

    button {
      width: 120px;
      border-color: #005192;
    }
  }
`;

const StyledClock = styled(UIBox)<{ $sticky?: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 24px;

  ${({ $sticky }): false | FlattenSimpleInterpolation | undefined =>
    $sticky &&
    css`
      flex-direction: row;
      margin-right: 8px;
    `};
`;

const StyledTimerIcon = styled(TimerIcon)<{ $sticky?: boolean }>`
  && {
    height: 22px;
    margin-bottom: 2px;
    fill: ${({ $sticky }): string => ($sticky ? '#fff' : '#929496')};
    margin-right: ${({ $sticky }): string => ($sticky ? '8px' : '0')};
  }
`;

const StyledTimerStickyButton = styled(UIButton)`
  && {
    background: transparent;
    width: auto !important;
    box-shadow: none;
    border: none;
    padding: 0;

    span {
      &:first-child {
        width: auto;
        min-width: auto;
        padding: 8px;
        border-radius: 100%;
        border: 3px solid #fff;
      }
    }
  }
`;

const StyledTimerActionIcon = styled('div')`
  width: 32px;
  height: 32px;
  margin-left: 12px;

  svg {
    fill: ${colors.primary};
  }
`;

const StyledTimerIconDecoration = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 3px solid ${colors.primary};
`;
//#endregion

export const Timer: FC<TimerProps> = memo(
  ({ $v2, sticky, inPause, setPause }): JSX.Element => {
    const { t } = useTranslation();
    const [initialTimestamp, setInitialTimestamp] = useState(DateTime.now());
    const [difference, setDifference] = useState<number>(0);

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
      if (inPause) {
        setDifference(
          difference + DateTime.now().diff(initialTimestamp, 'seconds').seconds
        );
      } else {
        setInitialTimestamp(DateTime.now());
      }
    }, [inPause]);

    useInterval(() => {
      if (!inPause) {
        const passedSeconds = Math.floor(
          difference + DateTime.now().diff(initialTimestamp, 'seconds').seconds
        );
        setSeconds(passedSeconds % 60);
        setMinutes(Math.floor(passedSeconds / 60));
      }
    }, 1000);

    if ($v2) {
      return (
        <UIBox alignItems="center">
          <StyledTimerIcon />
          <Typography size="xl" font="heavy">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
          <StyledTimerActionIcon onClick={(): void => setPause?.(!inPause)}>
            {inPause ? (
              <StyledTimerIconDecoration>
                <PlayArrowIcon />
              </StyledTimerIconDecoration>
            ) : (
              <StyledTimerIconDecoration>
                <StopRoundedIcon />
              </StyledTimerIconDecoration>
            )}
          </StyledTimerActionIcon>
        </UIBox>
      );
    }

    return (
      <StyledTimer $sticky={sticky}>
        <StyledClock $sticky={sticky}>
          <StyledTimerIcon $sticky={sticky} />
          <Typography size="xl" font="heavy">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        </StyledClock>
        {!sticky ? (
          <UIButton
            label={t('pause')}
            icon={inPause ? <PlayArrowIcon /> : <PauseIcon />}
            onClick={(): void => setPause && setPause!(!inPause)}
            outlined={true}
            disabled={false}
          />
        ) : (
          <StyledTimerStickyButton
            label=""
            icon={inPause ? <PlayArrowIcon /> : <StopRoundedIcon />}
            onClick={(): void => setPause && setPause(!inPause)}
            outlined={false}
            disabled={false}
          />
        )}
      </StyledTimer>
    );
  }
);

Timer.displayName = 'Timer';

export default Timer;
