import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

import {
  findUpc,
  findEpc,
  getLastPrints,
  clearProductDetails,
} from '@/features/print/printSlice';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';

import { Search as SearchIcon } from '@material-ui/icons';
import { ProductItemForPrint } from '@/api';

import { UIList } from '@/components/ui/List';
import { PageLoader } from '@/components/ui/PageLoader';
import UIAutocomplete from '@/components/ui/AutoComplete/AutoComplete';
// import { ModalPrintNoRfid } from '@/components/layout/ModalPrintNoRfid';
import { ModalPrint } from '@/components/layout/ModalPrint';
import { ScannedProductItemDate } from '@/components/layout/ScannedProductItemDate';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { useAppDispatch } from '@/app/store';
import Typography from '@/components/ui/Typography/Typography';

import { PrintMode } from '@/types/enum';

import { useHistory, useParams } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { PaginationList } from '@/components/layout/PaginationList';
import { ElasticHandlerRestService, FindLastPrintItem } from '@/api/receive';
import { printLabel } from '@/features/print/printSlice';
import { handlePrintLayout } from '@/utils/handlePrintLayout';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';

import {
  SearchContainer,
  StyledFiltersContainer,
  StyledDatePicker,
  SearchButton,
  StyledCalendarWrapper,
} from './style';
import {
  DATE_FORMATS,
  PRINT_NEW_TAG_CALENDAR_DAYS_AFTER,
  PRINT_NEW_TAG_DAYS_BEFORE,
} from '@/configs/settings';
import { isEmptyArray, isValidEpc, isValidUpc } from '@/utils/validation';
import {
  curDate,
  daysBefore,
  daysAfter,
  convertDateToDateTime,
} from '@/utils/date';
import { UIBox } from '@/components/ui/Box';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isRFIDStore } from '@/utils';
import ModalPrintTemp from '@/components/layout/ModalPrintTemp';
import { usePrintContext } from '@/context/Print';
import useTrackPrint from '../track/useTrackPrint';

const PagePrint: React.FC = memo((): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const autocompleteInputRef = useRef<HTMLInputElement>(null);
  const { mode } = useParams<{ mode: PrintMode }>();
  const { print, setPrint } = usePrintContext();
  const history = useHistory();

  const [startDate, setStartDate] = useState<MaterialUiPickersDate>();
  const [endDate, setEndDate] = useState<MaterialUiPickersDate>();

  const trackPrint = useTrackPrint();

  const { store } = useSelector(state => state.currentStore);
  const { username } = useSelector(state => state.user);
  const {
    isLoadingLastPrints,
    lastPrints,
    productDetails,
    lastPrintsError,
    printError,
    isLoadingProductDetails,
  } = useSelector(state => state.print);

  const { DATEPICKER } = DATE_FORMATS;

  const newPrint = mode === PrintMode.New;
  const printers = useMemo(() => store?.printers || [], [store?.printers]);

  const [isProductDetailsModalVisible, setProductDetailsModalVisisibility] =
    useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isPrintErrorVisible, setIsPrintErrorVisible] =
    useState<boolean>(false);
  const [noPrinterAvailable, setNoPrinterAvailable] = useState<boolean>(false);
  const [upcCode, setUpcCode] = useState<string>('');
  const [isUpcCodeValid, setUpcCodeValid] = useState<boolean>(false);
  const [isPrintModalVisible, setPrintModalVisibility] =
    useState<boolean>(false);
  const [productToPrint, setProductToPrint] = useState<ProductItemForPrint>();
  const [resultList, setResultList] = useState<string[]>([]);
  const [oldEpc, setOldEpc] = useState<string>();
  const [productDetailsItemFiltered, setProductDetailsItemFiltered] = useState<
    FindLastPrintItem[] | undefined
  >(lastPrints?.productDetailsItems);

  const today = curDate(DATEPICKER);

  const maxDate = daysAfter(
    PRINT_NEW_TAG_CALENDAR_DAYS_AFTER,
    DATEPICKER,
    convertDateToDateTime(startDate || new Date())
  );

  const minDate = daysBefore(PRINT_NEW_TAG_DAYS_BEFORE, DATEPICKER);

  const searchInputHandler: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setUpcCode(value);
  };

  const trackLastPrints = useSROTrackEvent(appInsights, 'LastPrints', {
    user: username,
    storeCode: store?.storeCode,
    lastPrints: productDetailsItemFiltered?.map(({ upcCode, epcCodes }) => ({
      upcCode,
      epcCodes: epcCodes?.map(({ epcCode }) => epcCode),
    })),
  });

  useEffect(() => {
    if (isRFIDStore(printers) && mode === PrintMode.Update) {
      history.push(`${AppRoutes.PRINT}/new`);
    }
  }, [history, mode, printers]);

  useEffect(() => {
    dispatch(getLastPrints());
  }, [dispatch, getLastPrints]);

  useEffect(() => {
    if (!startDate) {
      setProductDetailsItemFiltered(lastPrints?.productDetailsItems);
    } else if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      setProductDetailsItemFiltered(
        lastPrints?.productDetailsItems
          ?.map(item => ({
            ...item,
            epcCodes: item.epcCodes.filter(({ timestamp }) => {
              const timestampDate = new Date(
                `${timestamp?.replace(' ', 'T')}:00`
              );

              return timestampDate >= startDate && timestampDate <= endDate;
            }),
          }))
          .filter(({ epcCodes }) => epcCodes.length > 0)
      );
    }
  }, [startDate, endDate, lastPrints?.productDetailsItems]);

  useEffect(() => {
    if (productDetailsItemFiltered) {
      if (!isEmptyArray(productDetailsItemFiltered)) {
        trackLastPrints({
          user: username,
          storeCode: store?.storeCode,
          lastPrints: productDetailsItemFiltered?.map(
            ({ upcCode, epcCodes }) => ({
              upcCode,
              epcCodes: epcCodes?.map(({ epcCode }) => epcCode),
            })
          ),
        });
      }
    }
  }, [productDetailsItemFiltered, store?.storeCode, trackLastPrints, username]);

  useEffect(() => {
    if (upcCode.length > 0) {
      if (newPrint) {
        setUpcCodeValid(isValidUpc(upcCode));
      } else {
        setUpcCodeValid(isValidEpc(upcCode));
      }
    } else {
      setUpcCodeValid(true);
    }
  }, [mode, upcCode, newPrint]);

  useEffect(() => {
    if (productDetails) {
      setProductDetailsModalVisisibility(true);
      setProductToPrint(productDetails);
    }
  }, [productDetails]);

  useEffect(() => {
    if (printers.length === 0) {
      setNoPrinterAvailable(true);
    }
  }, [printers.length]);

  useEffect(() => {
    if (lastPrintsError) {
      setIsErrorVisible(true);
    }
  }, [lastPrintsError]);

  useEffect(() => {
    if (store) {
      const { printers } = store;

      if (isEmptyArray(printers)) {
        setIsPrintErrorVisible(true);
      }
    }
  }, [store]);

  useEffect(() => {
    setIsPrintErrorVisible(printError !== undefined);
  }, [printError]);

  useEffect(() => {
    if (upcCode.length === 0) {
      setResultList([]);
    }
  }, [upcCode]);

  useBreadcrumbs([{ title: t('printNewTag') }]);

  const findProduct = (upcCode: string | null): void => {
    if (newPrint) {
      dispatch(
        findUpc({
          upcCode,
          storeCode: store?.storeCode!,
        })
      );
    } else {
      dispatch(
        findEpc({
          epcCode: upcCode,
          storeCode: store?.storeCode!,
        })
      );

      setOldEpc(upcCode || '');
    }
  };

  const searchButtonHandler: React.MouseEventHandler = async () => {
    autocompleteInputRef.current?.focus();
  };

  const onAutoCompleteOptionSelect = (upcCode: string | null): void =>
    findProduct(upcCode);

  const getAutocompleteOptions = async (): Promise<void> => {
    if (upcCode.length >= 4) {
      if (newPrint) {
        try {
          const { filterValueList: upcList } =
            await ElasticHandlerRestService.elastichandlerSearchFromUpcByFilterAutocomplete(
              { filterValue: upcCode, filterType: 'upcCode' }
            );

          if (upcList) {
            if (!isEmptyArray(upcList)) {
              const list = upcList.map(({ filterValue }) => filterValue);

              return setResultList(list);
            }
          }

          return setResultList([]);
        } catch (e) {
          console.log('error', e);
        }
      } else {
        try {
          const { epcList } =
            await ElasticHandlerRestService.elastichandlerSearchEpcAutocomplete(
              { epcCode: upcCode }
            );

          if (epcList) {
            if (!isEmptyArray(epcList)) {
              const list = epcList.map(({ epcCode }) =>
                epcCode ? epcCode : ''
              );

              return setResultList(list);
            }
          }

          return setResultList([]);
        } catch (e) {
          console.log('error', e);
        }
      }
    }

    return setResultList([]);
  };

  const onAutoCompleteFocus = (): void => {
    if (upcCode.length === 0) {
      setResultList([]);
    }
  };

  const clearState = (): void => {
    setProductToPrint(undefined);
    dispatch(clearProductDetails());
  };

  const handleProductDetailsButtonClick = (): void => {
    setProductDetailsModalVisisibility(false);
    setPrintModalVisibility(true);
  };

  const handleProductDetailsClose = (): void => {
    setProductDetailsModalVisisibility(false);
    clearState();
  };

  const handlePrintModalClose = (): void => {
    setPrintModalVisibility(false);
    clearState();
  };

  const showPrintError = (visible: boolean, message?: string): void => {
    setPrint(prevState => ({
      ...prevState,
      error: {
        visible,
        message,
      },
    }));
  };

  const onPrintClick = async (): Promise<void> => {
    if (print.error.message) {
      showPrintError(false);
    }

    if (store && store.storeCode && productToPrint) {
      try {
        const { upcCode, currency } = productToPrint;

        const response = await dispatch(
          printLabel({
            storeCode: store.storeCode,
            layout: handlePrintLayout(
              currency || '',
              print.printer?.isRfid || false
            ),
            serialNumberPrinter: print.printer?.printerCode || '',
            upcCode: upcCode!,
            confirmPrint: print.printer?.isRfid ? true : false,
            epc: !newPrint ? oldEpc : undefined,
          })
        ).unwrap();

        if (response.epc) {
          /**
           * Redux state is gonna change on the start use reader
           * if the selected printer is NO-RFID
           */
          if (print.printer?.isRfid) {
            setPrintModalVisibility(false);
            await dispatch(getLastPrints());
            clearState();
            await trackPrint(upcCode!, response.epc);
          } else {
            setPrint(prevState => ({
              ...prevState,
              data: {
                printedEPC: response.epc,
              },
            }));
          }
        }
      } catch {
        showPrintError(true, t('error.something_went_wrong'));
      }
    }
  };

  /**
   * It's gonna be executed only if the selected printer
   * is NO-RFID and the active step of the print modal is 1
   */
  const onStartUseReaderClick = async (): Promise<void> => {
    if (print.printer && !print.printer.isRfid) {
      if (productToPrint) {
        setPrintModalVisibility(false);
        await dispatch(getLastPrints());
        clearState();
        await trackPrint(upcCode!);
      }
    }
  };

  const handleBackClick = (): void => {
    isRFIDStore(printers)
      ? history.push(AppRoutes.INTRO)
      : history.push(AppRoutes.PRINT);
  };

  return (
    <>
      <Typography
        as="h1"
        font="heavy"
        style={{
          textAlign: 'center',
          fontSize: 18,
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        {t('printNewTag.title', { type: newPrint ? 'UPC' : 'EPC' })}
      </Typography>
      <StyledFiltersContainer>
        {newPrint && (
          <UIBox mt="3px" flexDirection="column">
            <UIBox>
              <Typography font="medium">
                {t('printNewTag.datePicker.placeHolder')}
              </Typography>
            </UIBox>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <StyledCalendarWrapper mt={2}>
                <StyledDatePicker
                  autoOk
                  disableToolbar
                  placeholder={t('start.date')}
                  inputVariant="outlined"
                  id="start-date"
                  variant="inline"
                  InputLabelProps={{ shrink: true }}
                  format={DATEPICKER}
                  inputProps={{
                    defaultValue: undefined,
                  }}
                  value={
                    startDate
                      ? convertDateToDateTime(startDate).toFormat(DATEPICKER)
                      : null
                  }
                  minDate={minDate}
                  maxDate={today}
                  onChange={(date): void => {
                    setStartDate(new Date(date!.getTime()));

                    if (!endDate) {
                      return setEndDate(new Date(date!.getTime()));
                    }

                    if (date! < startDate! || date! > endDate!) {
                      setEndDate(new Date(date!.getTime()));
                    }
                  }}
                />
              </StyledCalendarWrapper>
              <StyledCalendarWrapper mt={2}>
                <StyledDatePicker
                  autoOk
                  disableToolbar
                  placeholder={t('end.date')}
                  inputVariant="outlined"
                  id="end-date"
                  variant="inline"
                  InputLabelProps={{ shrink: true }}
                  disabled={!startDate}
                  format={DATEPICKER}
                  value={
                    endDate
                      ? convertDateToDateTime(endDate).toFormat(DATEPICKER)
                      : null
                  }
                  minDate={convertDateToDateTime(startDate!).toFormat(
                    DATEPICKER
                  )}
                  maxDate={maxDate}
                  onChange={(date): void => setEndDate(date)}
                />
              </StyledCalendarWrapper>
            </MuiPickersUtilsProvider>
          </UIBox>
        )}

        <SearchContainer ml="auto">
          <UIAutocomplete
            disableClearable
            rounded
            width={350}
            minSearchTermLength={4}
            label={t('searchPrintItem')}
            searchTerm={upcCode}
            options={resultList}
            getOptions={getAutocompleteOptions}
            inputRef={autocompleteInputRef}
            onChange={searchInputHandler}
            error={!isUpcCodeValid}
            onFocus={onAutoCompleteFocus}
            loadingText={t('searching')}
            noOptionsText={t('error.no_results_available')}
            onOptionSelect={(_e, upcCode): void =>
              onAutoCompleteOptionSelect(upcCode)
            }
            helperText={
              !isUpcCodeValid &&
              (newPrint ? t('error.invalidUpc') : t('error.invalidEpc'))
            }
            inputProps={
              newPrint
                ? { maxLength: 13, minLength: 5 }
                : { maxLength: 24, minLength: 24 }
            }
          />

          <SearchButton
            label={t('search')}
            disabled={
              isLoadingProductDetails || upcCode.length === 0 || !isUpcCodeValid
            }
            onClick={searchButtonHandler}
            startIcon={<SearchIcon />}
          />
        </SearchContainer>
      </StyledFiltersContainer>

      {newPrint ? (
        isLoadingLastPrints ? (
          <PageLoader />
        ) : (
          <UIList
            shadowed
            backgrounded
            rounded
            itemsCount={t('lastPrints')}
            margin={[0, 0, 100]}
          >
            <PaginationList
              data={productDetailsItemFiltered || []}
              infiniteScroll={false}
              pageSize={25}
              renderItem={(
                productDetailsItem: NonNullable<FindLastPrintItem>,
                index: number
              ): JSX.Element => (
                <ScannedProductItemDate
                  $found
                  productItem={productDetailsItem}
                  key={`${productDetailsItem.upcCode}-${index}`}
                  $v2
                />
              )}
            />
          </UIList>
        )
      ) : null}

      <CTAContainer type="BACK" onClick={handleBackClick} />

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={
          lastPrintsError?.status === 400
            ? t('error.upcNotFound')
            : `${t('error.print_new')}: ${lastPrintsError?.message}`
        }
      />

      <ErrorSnackbar
        open={isPrintErrorVisible}
        setIsOpen={setIsPrintErrorVisible}
        errorMessage={
          `${t('error.print_new')}: ${
            printError?.body
              ? printError.body.message
              : t('error.printGenericError')
          }` || ''
        }
      />

      <ModalPrintTemp
        hideReasons
        encodeTag={!!newPrint}
        open={isPrintModalVisible}
        onClose={handlePrintModalClose}
        productToPrint={productToPrint}
        onStartClick={onStartUseReaderClick}
        onPrintClick={onPrintClick}
        printers={
          !newPrint ? printers.filter(({ isRfid }) => !isRfid) : undefined
        }
      />

      {/* <ModalPrintNoRfid
        printedEPC={printedEPC}
        isTagVisible={isTagVisible}
        setPrintedEPC={setPrintedEPC}
        setTagVisibility={setTagVisibility}
        mode={mode}
        product={{
          ...productDetails,
          upcCode: productDetails?.upcCode!,
          layout:
            productDetails?.currency === 'USD'
              ? 'SRO NA CREATE-TAG'
              : 'SRO EMEA CREATE-TAG',
          serialNumberPrinter: store?.printers?.[0].printerCode!,
          storeCode: store?.storeCode!,
        }}
        onCloseHandler={(): void => {
          toggleProductDetailsModalVisibility();
          setPrintModalVisibility(false);
        }}
        open={isPrintModalVisible}
      /> */}

      {productDetails && (
        <ModalPrint
          open={isProductDetailsModalVisible}
          onClose={handleProductDetailsClose}
          product={productDetails}
          mode={mode}
          buttonDisabled={printers.length === 0}
          onButtonClick={handleProductDetailsButtonClick}
        />
      )}

      <AlertSnackbar
        open={noPrinterAvailable}
        setIsOpen={setNoPrinterAvailable}
        message={t('error.noPrinterAvailable')}
      />
    </>
  );
});

PagePrint.displayName = 'PagePrint';

export default PagePrint;
