import { FC, useEffect, useState } from 'react';
import {
  StyledAddRoleContainer,
  StyledLoaderWrapper,
  StyledTypography,
} from './style';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory, useParams } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { useTranslation } from 'react-i18next';
import {
  PermissionsAreLoading,
  PermissionsError,
  SplittedPermissionsSelector,
  UpdatingRoleSelector,
} from '@/features/profiling/rolesSelector';
import {
  disableAllMainPermissions,
  enableAllMainPermissions,
  disableAllPermissions,
  setPermissionValue,
  findAllPermissions,
  setRole,
  createRole,
  updateRole,
} from '@/features/profiling/rolesSlice';
import { useAppDispatch } from '@/app/store';
import GraficTextField from '@/components/ui/GraficTextField/GraficTextField';
import { FieldDataValuesValues } from '@/types/fieldDataValues';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UILoader } from '@/components/ui/Loader';
import { UrlUpdateRole } from './types';
import { useAsync } from 'react-use';
import { Typography } from '@/components/ui/Typography';
import { UIChip } from '@/components/ui/Chip';

import Link from '@material-ui/core/Link/index';
import styled from 'styled-components';

export const ShowChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 28px 0;
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const StyledSelectAllLink = styled(Link)`
  width: max-content;
  cursor: pointer;
`;

const PageAddUpdateRole: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { role } = useParams<UrlUpdateRole>();
  const [roleName, setRoleName] = useState<FieldDataValuesValues>({});

  const { mainPermissions, additionalPermissions, mainPermissionAllSelected } =
    SplittedPermissionsSelector();

  const { isUpdatingRole, updateRoleError, isUpdatedRole } =
    UpdatingRoleSelector();

  const [permissionClicked, setPermissionClicked] = useState<string[]>([]);

  const [isPermissionsSelected, setPermissionsSelected] = useState<boolean>(
    mainPermissionAllSelected
  );

  const [openedSnackbarError, setOpenedSnackbarError] =
    useState<boolean>(false);

  const [openedSnackbarCreateError, setOpenedSnackbarCreateError] =
    useState<boolean>(false);

  const permissionsError = PermissionsError();
  const permissionsAreLoading = PermissionsAreLoading();
  const currentRole = role;

  useEffect(() => {
    if (role?.toLocaleLowerCase() === 'admin') {
      history.push(AppRoutes.MANAGE_ROLES);
    }
  }, [history, role]);

  useAsync(async () => {
    await dispatch(findAllPermissions(currentRole));
    await dispatch(setRole(role || ''));
  }, [dispatch, role]);

  useEffect(() => {
    setOpenedSnackbarError(typeof permissionsError !== 'undefined');
  }, [permissionsError]);

  useEffect(() => {
    setOpenedSnackbarCreateError(typeof updateRoleError !== 'undefined');
  }, [updateRoleError]);

  useEffect(() => {
    if (isUpdatedRole) {
      history.push(AppRoutes.MANAGE_ROLES);
    }
  }, [isUpdatedRole, history]);

  useEffect(() => {
    if (currentRole) {
      const permissionEnabled = mainPermissions
        .concat(additionalPermissions)
        .filter(({ active }) => active)
        .map(({ label }) => label);
      setPermissionClicked(permissionEnabled);
    }
    setPermissionsSelected(mainPermissionAllSelected);
  }, [
    mainPermissions.length,
    currentRole,
    additionalPermissions.length,
    mainPermissionAllSelected,
  ]);

  const changePermissionValue = async (name: string): Promise<void> => {
    await dispatch(setPermissionValue(name));
    setPermissionClicked(prevState => {
      if (prevState.includes(name)) {
        return prevState.filter(permission => permission !== name);
      }

      return [...prevState, name];
    });
  };

  const isValid = !roleName.isEmpty;

  const handleClick = async (): Promise<void> => {
    if (currentRole) {
      const currentRoleConfiguration = {
        oldRoleName: currentRole,
        roleName: roleName.currentVal || '',
        isEnabled: true,
        permissions: permissionClicked,
      };

      await dispatch(updateRole(currentRoleConfiguration));
    } else {
      const currentRoleConfiguration = {
        roleName: roleName.currentVal || '',
        isEnabled: true,
        permissions: permissionClicked,
      };

      await dispatch(createRole(currentRoleConfiguration));
    }
  };

  const handleBackClick = (): void => {
    dispatch(disableAllPermissions());
    history.push(AppRoutes.MANAGE_ROLES);
  };

  const selectAllClickHandler = (): void => {
    const permissions = mainPermissions.map(({ label }) => label);

    const additionalClicked = permissionClicked.filter(
      perm => !permissions.includes(perm)
    );

    if (!isPermissionsSelected) {
      setPermissionClicked(additionalClicked.concat(permissions));
      dispatch(enableAllMainPermissions());
    } else {
      setPermissionClicked(additionalClicked);
      dispatch(disableAllMainPermissions());
    }
    setPermissionsSelected(!isPermissionsSelected);
  };

  return (
    <StyledAddRoleContainer>
      <ErrorSnackbar
        open={openedSnackbarError}
        setIsOpen={setOpenedSnackbarError}
        errorMessage={permissionsError?.body || permissionsError?.message}
      />
      <ErrorSnackbar
        open={openedSnackbarCreateError}
        setIsOpen={setOpenedSnackbarCreateError}
        errorMessage={updateRoleError?.body || updateRoleError?.message}
      />
      <GraficTextField
        name="roleName"
        title="Title role"
        required
        exportValue={setRoleName}
        updateMode="change"
        defaultValue={currentRole}
      />
      <StyledTypography>{t('pageAddUpdateRole.description')}</StyledTypography>
      {permissionsAreLoading && (
        <StyledLoaderWrapper>
          <UILoader />
        </StyledLoaderWrapper>
      )}
      {!permissionsAreLoading && (
        <>
          <ShowChipContainer>
            <Typography font="black">{t('mainPermissions')}</Typography>
            <StyledSelectAllLink onClick={selectAllClickHandler}>
              {isPermissionsSelected
                ? t('showChips.selectAll.1')
                : t('showChips.selectAll.0')}
            </StyledSelectAllLink>
            <ChipContainer>
              {mainPermissions.map(({ label, action }) => (
                <UIChip
                  clickable
                  label={action}
                  key={label}
                  onClick={(): Promise<void> => changePermissionValue(label)}
                  $activeBackground={
                    permissionClicked.includes(label) ? 'primary' : 'grey'
                  }
                />
              ))}
            </ChipContainer>
          </ShowChipContainer>
          <ShowChipContainer>
            <Typography font="black">{t('additionalPermissions')}</Typography>
            <ChipContainer>
              {additionalPermissions.map(({ label, action }) => (
                <UIChip
                  clickable
                  key={label}
                  label={action}
                  onClick={(): Promise<void> => changePermissionValue(label)}
                  $activeBackground={
                    permissionClicked.includes(label) ? 'primary' : 'grey'
                  }
                />
              ))}
            </ChipContainer>
          </ShowChipContainer>
        </>
      )}
      <CTAContainer
        type="TWO_BUTTONS"
        onClick={(): Promise<void> => handleClick()}
        onBackClick={(): void => handleBackClick()}
        disabledMainAction={
          isUpdatingRole || !isValid || permissionClicked.length === 0
        }
        mainButtonLabel={t('save')}
      />
    </StyledAddRoleContainer>
  );
};

export default PageAddUpdateRole;
