import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@/hooks/useSelector';
import { useHistory } from 'react-router';

import { getDeliveryProducts } from '@/features/products/deliveryProductsSlice';
import { getScannedProducts } from '@/features/products/scannedProductsSlice';

import { CTAContainer } from '@/components/layout/CTAContainer';
import { DeliveryProductItem } from '@/components/layout/DeliveryProductItem';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { PageLoader } from '@/components/ui/PageLoader';
import { UIList } from '@/components/ui/List';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAppDispatch } from '@/app/store';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { ProductDetailsItem } from '@/api/receive';
import { PaginationList } from '@/components/layout/PaginationList';
import { initDeliveryList } from '@/features/deliveries/deliveriesSlice';
import { AppRoutes } from '@/app/routers';
import { UIWarning } from '@/components/ui/Warning';
// import { EnumMode } from '@/types/enum';
import { useAsync } from 'react-use';

const PageReceivingDeliveries: FC = memo((): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { products, isLoading, error } = useSelector(
    state => state.deliveryProducts
  );
  const { tags } = useSelector(state => state.devices);

  const { selectedDeliveries } = useSelector(state => state.deliveries);

  const [openModalScanWizard, setOpenModalScanWizard] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState('');

  const history = useHistory();

  useBreadcrumbs([
    {
      title: t('deliveries'),
    },
  ]);

  useEffect(() => {
    if (selectedDeliveries.length === 0) {
      history.push('/');
    }
  }, [dispatch, history, selectedDeliveries]);

  useEffect(() => {
    const deliveryCodes = selectedDeliveries.map(item => item.codeDelivery);
    dispatch(getDeliveryProducts(deliveryCodes));
  }, [dispatch, selectedDeliveries]);

  useAsync(async () => {
    await dispatch(getScannedProducts(tags));
  }, [dispatch, tags]);

  useEffect(() => {
    if (error) {
      dispatch(initDeliveryList());
      history.push(AppRoutes.RECEIVING);
      setIsErrorVisible(true);
    }
  }, [error]);

  const selectedDeliveriesCodes = selectedDeliveries
    .map(item => item.codeDelivery)
    .join(', #');

  const hasEpcDifference = selectedDeliveries.some(({ soldItem }) => soldItem);

  const selectedDeliveriesItemsCount = selectedDeliveries.reduce(
    (a, b) => Number(a) + Number(b.numberItems),
    0
  );

  const handleOnClick = (e: React.MouseEvent, upcCode: string): void => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (
      typeof target.className === 'object' ||
      target.className.includes('Accordion')
    )
      return;

    setSelectedProduct(upcCode);
    setOpenDetailModal(true);
  };

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <ModalProductDetails
        open={openDetailModal}
        onClose={(): void => setOpenDetailModal(false)}
        productCode={selectedProduct}
      />
      <ModalScanDetailsV2
        resetDeviceInUse
        open={openModalScanWizard}
        // hideModes={[EnumMode.FIND]}
        onClose={(): void => setOpenModalScanWizard(false)}
        nextPageURL="/receiving/products/scan"
      />
      <UIList
        title={
          selectedDeliveries.length > 1
            ? t('list.multipleDeliveries') + `: #${selectedDeliveriesCodes}`
            : t('list.delivery') + `: #${selectedDeliveriesCodes}`
        }
        itemsCount={t('shipmentQty', {
          count: Number(selectedDeliveriesItemsCount),
        })}
        itemsWarning={
          <>
            {hasEpcDifference && <UIWarning label={t('receiving.soldItems')} />}
          </>
        }
        rounded
        backgrounded
        shadowed
        margin={[0, 0, 100]}
      >
        <PaginationList
          data={products}
          infiniteScroll={true}
          pageSize={6}
          renderItem={(
            props: NonNullable<ProductDetailsItem>,
            index: number
          ): JSX.Element => (
            <DeliveryProductItem
              {...props}
              onClick={(e: React.MouseEvent): void =>
                handleOnClick(e, props.upcCode!)
              }
              key={`${props.brandCode}-${index}-productItem`}
            />
          )}
        />
      </UIList>

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={error?.body || t('error.receiving_products')}
      />

      <CTAContainer
        type="SCAN NOW"
        disabled={false}
        onClick={(): void => setOpenModalScanWizard(true)}
      />
    </>
  );
});

PageReceivingDeliveries.displayName = 'PageReceivingDeliveries';

export default PageReceivingDeliveries;
