import { UserPermissions } from '../types/enum';
import { UserData } from '../types/user';

export const userHasAllPermission = (
  user: UserData,
  requestedPermissions: Array<string>
): boolean => {
  const { permissions } = user;
  if (
    requestedPermissions.length > 0 &&
    permissions &&
    permissions.length > 0
  ) {
    return requestedPermissions.every(permission =>
      permissions.includes(permission)
    );
  }
  return false;
};

export const isAdmin = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.ADMIN]);
};

export const isDeliveryManager = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.DELIVERY_MANAGER]);
};

export const isSOHExtension = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.SOHEXTENSION]);
};

export const isRoleManager = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.ADMIN]);
};

export const isRecallAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.RECALL]);
};

export const isReceivingAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.RECEIVING]);
};

export const isSaleAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.SALE]);
};

export const isCycleCountAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.CYCLECOUNT]);
};

export const isCountingAllowed = (user: UserData): boolean => {
  return userHasSomePermission(user, [
    UserPermissions.CYCLECOUNT,
    UserPermissions.SOHA_ADJUSTMENT_SUPER_USER,
    UserPermissions.SOHA_ADJUSTMENT_USER,
  ]);
};

export const isSohaAllowed = (user: UserData): boolean => {
  return userHasSomePermission(user, [
    UserPermissions.SOHA_ADJUSTMENT_SUPER_USER,
    UserPermissions.SOHA_ADJUSTMENT_USER,
  ]);
};

export const isSohSuperUser = (user: UserData): boolean => {
  return userHasAllPermission(user, [
    UserPermissions.SOHA_ADJUSTMENT_SUPER_USER,
  ]);
};

export const isStoreTransferAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.STORE2STORE]);
};

export const isStockSummaryAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.STOCKSUMMARY]);
};

export const isRepricingAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.REPRICING]);
};

export const isPrintersAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.PRINTTAG]);
};

export const isVirtualBuddy = (user: UserData): boolean => {
  return (
    userHasAllPermission(user, [UserPermissions.FOLLOW_CONFIRM_INVENTORY]) &&
    user.roles!.includes('Virtual Buddy')
  );
};

export const isStoreMigrator = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.STOREMIGRATOR]);
};

export const isUsersManager = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.MANAGEUSERS]);
};

export const isReasonsManager = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.MANAGEREASON]);
};

export const isReasonsUploader = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.UPLOADREASON]);
};

export const isStoresManager = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.MANAGESTORES]);
};

export const isStoreDamages = (user: UserData): boolean => {
  return userHasSomePermission(user, [
    UserPermissions.CREATEDAMAGE,
    UserPermissions.VALIDATEDAMAGE,
  ]);
};

export const isStoreDamagesCreate = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.CREATEDAMAGE]);
};

export const isStoreDamagesValidate = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.VALIDATEDAMAGE]);
};

export const isCustomerReturns = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.CUSTOMERRETURNS]);
};

export const isInventoryOperator = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.INVENTORY]);
};

export const isInventoryOwner = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.BEGIN_INVENTORY]);
};

export const isInventoryHider = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.HIDE_INVENTORY]);
};

export const isLoaderSuperUser = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.LOADER_SUPER_USER]);
};

export const isLoaderOperator = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.LOADER_OPERATOR]);
};

export const isStockSummaryPrinter = (user: UserData): boolean => {
  return userHasAllPermission(user, [
    UserPermissions.STOCK_SUMMARY_PRINT_ENABLED,
  ]);
};

export const isSaleExceptionsManager = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.MANAGE_SALE_EXCEPTIONS]);
};

export const isDeleteEPCAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.DELETE_EPC]);
};

export const isManualLoaderAllowed = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.MANUAL_LOADER]);
};

export const isAppRecapDownloader = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.APP_REPORT_DOWNLOADER]);
};

export const isSapRecapDownloader = (user: UserData): boolean => {
  return userHasAllPermission(user, [UserPermissions.SAP_REPORT_DOWNLOADER]);
};

export const isReaderUploadAllowed = (user: UserData): boolean => {
  return userHasSomePermission(user, [
    UserPermissions.LOADER_OPERATOR,
    UserPermissions.LOADER_SUPER_USER,
  ]);
};

export const userHasSomePermission = (
  user: UserData,
  requestedPermissions: Array<string>
): boolean => {
  const { permissions } = user;
  if (
    requestedPermissions.length > 0 &&
    permissions &&
    permissions.length > 0
  ) {
    return requestedPermissions.some(permission =>
      permissions.includes(permission)
    );
  }
  return false;
};

export const isControlPanelAllowed = (user: UserData): boolean => {
  return userHasSomePermission(user, [
    UserPermissions.MANAGEREASON,
    UserPermissions.MANAGEUSERS,
    UserPermissions.MANAGESTORES,
  ]);
};

export const isStoreMigratorAllowed = (user: UserData): boolean => {
  return userHasSomePermission(user, [
    UserPermissions.STOREMIGRATOR,
    UserPermissions.MANUAL_LOADER,
    UserPermissions.LOADER_OPERATOR,
    UserPermissions.LOADER_SUPER_USER,
    UserPermissions.DELETE_EPC,
  ]);
};
