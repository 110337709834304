import React, { useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';

import { UIBox } from '@/components/ui/Box';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Typography } from '@/components/ui/Typography';

import {
  StyledAction,
  StyledCountedItems,
  StyledCountedItemsWrapper,
  StyledHeader,
  StyledInventoryInfo,
  StyledInventoryItem,
  StyledInventoryItemWrapper,
  StyledListInventory,
  StyledLogo,
  StyledOperators,
  StyledRfidWrapper,
  StyledSoldWrapper,
  StyledTitle,
} from './style';
import { DateTime } from 'luxon';
import { InventoryService } from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import type { ShowDetailsResponse } from '@/api/receive';
import { isVirtualBuddy } from '@/utils/user';

const PageInventoryDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ inventoryStoreCode: string }>();
  const history = useHistory();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const user = useSelector(state => state.user);
  const [fetchDetailsError, setFetchDetailsError] = useState<boolean>(false);
  const [inventoryDetails, setInventoryDetails] =
    useState<ShowDetailsResponse>();

  useAsync(async () => {
    if (storeCode) {
      try {
        const response = await InventoryService.inventoryShowDetails({
          idInventory: id,
          storeCode:
            location.state && location.state.inventoryStoreCode
              ? location.state.inventoryStoreCode
              : storeCode,
        });

        if (response) {
          setInventoryDetails(response);
        }
      } catch {
        setFetchDetailsError(true);
      }
    }
  }, []);

  const printClickHandler = (): void => {
    window.print();
  };

  const itemsCount = [
    {
      label: t('countedItemsInInventory'),
      count: inventoryDetails?.totalScanned,
    },
    {
      label: t('quantityInStockPreInventory'),
      count: inventoryDetails?.inStockQty,
    },
  ];

  const soldItem = inventoryDetails?.rfidItems?.find(
    ({ rfidStatus }) => rfidStatus === 'Sold'
  );

  const rfidItems = inventoryDetails?.rfidItems?.filter(
    ({ rfidStatus }) => rfidStatus !== 'Sold' && rfidStatus !== 'In stock'
  );

  const inventoryDate =
    inventoryDetails?.inventoryDate &&
    DateTime.fromFormat(inventoryDetails.inventoryDate, 'yyyyMMdd').toFormat(
      'yyyy LLLL dd',
      {
        locale: i18n.language,
      }
    );

  return (
    <>
      {inventoryDetails && (
        <UIBox width="100%" flexDirection="column" padding={3}>
          {/* Its showed only on print */}
          <StyledLogo />
          <StyledHeader>
            <StyledInventoryInfo>
              <UIBox mb={2} alignItems="center">
                <Typography size="sm" color="grey">
                  {t('storeCode')}:
                </Typography>
                <Typography size="sm" font="heavy" margin="0 0 0 4px">
                  {inventoryDetails.storeCode}
                </Typography>
              </UIBox>
              <UIBox alignItems="center">
                <Typography size="sm" color="grey">
                  {t('operator')}:
                </Typography>
                <StyledOperators>
                  {inventoryDetails.operators?.map(
                    ({ firstName, lastName, role }) => (
                      <Typography size="sm" font="heavy" margin="0 0 0 4px">
                        {firstName} {lastName}{' '}
                        {role === 'Inventory Owner' && `(${t('owner')})`}
                      </Typography>
                    )
                  )}
                </StyledOperators>
              </UIBox>
            </StyledInventoryInfo>
            <StyledTitle>
              <Typography font="heavy" size="lg" margin="0 auto 0 auto">
                {t('inventory')}: {inventoryDate} {inventoryDetails.timeDuring}
              </Typography>
            </StyledTitle>
            <UIBox>
              <StyledAction
                label={t('printInventoryDetails')}
                icon="print"
                onClick={printClickHandler}
              />
            </UIBox>
          </StyledHeader>
          <StyledCountedItemsWrapper>
            <StyledCountedItems>
              {itemsCount.map(({ label, count }) => (
                <UIBox
                  ml={2}
                  maxWidth="150px"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  textAlign="center"
                >
                  <Typography size="sm" color="grey" margin="0 0 8px 0">
                    {label}
                  </Typography>
                  <Typography font="heavy" size="xl">
                    {count}
                  </Typography>
                </UIBox>
              ))}
            </StyledCountedItems>
          </StyledCountedItemsWrapper>
          <StyledListInventory>
            {rfidItems && rfidItems.length > 0 && (
              <StyledRfidWrapper>
                <Typography font="heavy" margin="0 0 16px 0">
                  {t('scannedItemsNotInInventory')}
                </Typography>
                <StyledInventoryItemWrapper>
                  {rfidItems.map(({ rfidStatus, rfidQty }) => (
                    <StyledInventoryItem>
                      <Typography size="sm" color="grey">
                        {t('rfidStatusItems', { status: rfidStatus })}
                      </Typography>
                      <Typography size="xl" font="heavy">
                        {rfidQty}
                      </Typography>
                    </StyledInventoryItem>
                  ))}
                </StyledInventoryItemWrapper>
              </StyledRfidWrapper>
            )}
            <StyledSoldWrapper>
              <Typography font="heavy" margin="0 0 16px 0">
                {t('soldScannedItems')}
              </Typography>
              <StyledInventoryItemWrapper>
                <StyledInventoryItem>
                  <Typography size="sm" color="grey">
                    {t('rfidSoldItems')}
                  </Typography>
                  <Typography size="xl" font="heavy">
                    {soldItem ? soldItem.rfidQty : 0}
                  </Typography>
                </StyledInventoryItem>
              </StyledInventoryItemWrapper>
            </StyledSoldWrapper>
          </StyledListInventory>
        </UIBox>
      )}
      <CTAContainer
        type="ONE_BUTTON"
        label={t('back')}
        onClick={(): void =>
          isVirtualBuddy(user)
            ? history.push(AppRoutes.INVENTORY_VIRTUAL_BUDDY)
            : history.push(AppRoutes.INVENTORY)
        }
      />

      <ErrorSnackbar
        open={fetchDetailsError}
        setIsOpen={setFetchDetailsError}
        errorMessage={t('error.inventory_details_loading')}
      />
    </>
  );
};

export default PageInventoryDetails;
