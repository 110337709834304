import { FC, memo, useState, useEffect } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
  getDeliveryList,
  selectDelivery,
  deselectDelivery,
} from '@/features/deliveries/deliveriesSlice';

import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';

import { UIList } from '@/components/ui/List';
import { DeliveryListItem } from '@/components/layout/DeliveryListItem';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { PageLoader } from '@/components/ui/PageLoader';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAppDispatch } from '@/app/store';
import { AppRoutes } from '@/app/routers';

const PageReceiving: FC = memo((): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [checked, setChecked] = useState<number[]>([]);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

  const { deliveryItems, selectedDeliveries, error, isLoading } = useSelector(
    state => state.deliveries
  );

  const { error: errorDeliveryInUse } = useSelector(
    state => state.deliveryProducts
  );

  useBreadcrumbs([
    {
      title: t('page.receiving'),
    },
  ]);

  useEffect(() => {
    dispatch(getDeliveryList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setIsErrorVisible(true);
    }
  }, [error]);

  useEffect(() => {
    if (errorDeliveryInUse) {
      setIsErrorVisible(true);
    }
  }, [dispatch, errorDeliveryInUse]);

  const handleToggle = (index: number): void => {
    const currentIndex = checked.indexOf(index);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(index);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelect = (e: React.MouseEvent): void => {
    e.preventDefault();
    history.push(AppRoutes.RECEIVING_DELIVERIES);
  };

  const handleClick = (
    e: React.MouseEvent,
    codeDelivery: string,
    index: number
  ): void => {
    e.preventDefault();

    handleToggle!(index);

    deliveryItems.forEach((item): void => {
      const itemInState = selectedDeliveries.some(
        selectedItem => selectedItem.codeDelivery === codeDelivery
      );

      if (!item) return;

      if (!itemInState) {
        if (item.codeDelivery === codeDelivery) {
          dispatch(selectDelivery(item));
        }
      } else {
        if (item.codeDelivery === codeDelivery) {
          dispatch(deselectDelivery(item));
        }
      }
    });
  };

  return isLoading && deliveryItems.length === 0 ? (
    <PageLoader />
  ) : (
    <>
      <UIList
        title={
          deliveryItems.length > 0
            ? t('list.title.deliveries_available')
            : t('list.title.deliveries_unavailable')
        }
        margin={[0, 0, 100]}
      >
        {deliveryItems?.map(
          (delivery, i): JSX.Element => (
            <DeliveryListItem
              key={i + delivery.codeDelivery}
              index={i}
              handleClick={handleClick}
              checked={checked}
              {...delivery}
            />
          )
        )}
      </UIList>

      <ErrorSnackbar
        open={isErrorVisible}
        setIsOpen={setIsErrorVisible}
        errorMessage={errorDeliveryInUse?.body || t('error.receiving')}
      />

      <CTAContainer
        type="SELECT"
        onClick={handleSelect}
        disabled={checked.length === 0 ? true : false}
      />
    </>
  );
});

PageReceiving.displayName = 'PageReceiving';

export default PageReceiving;
