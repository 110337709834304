import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReceiveShipmentsService } from '@/api';
import type { GetDeliveryDetailsResponse, ApiError } from '@/api/receive';
import type { ManageDeliveryInitialState } from '@/types/deliveriesManage';
import type { RootState } from '@/app/rootReducer';
import { EpcStatusValues } from '@/types/enum';
import { DeliveryStatus } from '@/pages/PageManageDelivery/types';

const initialState: ManageDeliveryInitialState = {
  checkedEpcs: [],
  searchedStoreCode: '',
  searchedDeliveryCode: '',
  searchedStoreCodes: [],
  searchedDeliveryCodes: [],
  deliveryStatuses: ['New', 'In Use', 'Stolen'],
};

export const fetchDeliveryDetails = createAsyncThunk<
  GetDeliveryDetailsResponse | undefined,
  { deliveryNumber: string; storeCode: string },
  { state: RootState; rejectValue: ApiError }
>(
  'manageDelivery/fetchDeliveryDetails',
  async ({ deliveryNumber, storeCode }, { rejectWithValue }) => {
    try {
      const response = await ReceiveShipmentsService.receiveshipDeliveryDetails(
        {
          deliveryNumber,
          storeCode,
        }
      );

      if (response) {
        return response;
      }

      return undefined;
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

const manageDeliverySlice = createSlice({
  name: 'manageDelivery',
  initialState,
  reducers: {
    setSearchedStoreCode: (state, { payload }: PayloadAction<string>) => {
      state.searchedStoreCode = payload;
    },
    setSearchedDeliveryCode: (state, { payload }: PayloadAction<string>) => {
      state.searchedDeliveryCode = payload;
    },
    setSearchedStoreCodes: (state, { payload }: PayloadAction<string[]>) => {
      state.searchedStoreCodes = payload;
    },
    setSearchedDeliveryCodes: (state, { payload }: PayloadAction<string[]>) => {
      state.searchedDeliveryCodes = payload;
    },
    setDeliveryStatuses: (
      state,
      { payload }: PayloadAction<DeliveryStatus>
    ) => {
      const statusIndex = state.deliveryStatuses.findIndex(s => s === payload);

      if (statusIndex !== -1) {
        state.deliveryStatuses.splice(statusIndex, 1);
      } else {
        state.deliveryStatuses.push(payload);
      }
    },
    setManagedDeliveryInUse: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.deliveryManagedInUse = payload;
    },
    setShouldFetchDeliveries: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldFetchDeliveries = payload;
    },
    checkEpcs: (state, { payload }: PayloadAction<string[]>) => {
      state.checkedEpcs = payload;
    },
    checkEpc: (state, { payload }: PayloadAction<string>) => {
      const epcIndex = state.checkedEpcs.findIndex(
        epcCode => epcCode === payload
      );

      if (epcIndex !== -1) {
        state.checkedEpcs.splice(epcIndex, 1);
      } else {
        state.checkedEpcs = [...state.checkedEpcs, payload];
      }
    },
    changeEpcStatus: (
      state,
      { payload }: PayloadAction<{ epcs: string[]; status: EpcStatusValues }>
    ) => {
      const { epcs } = payload;

      if (state.deliveryDetails && state.deliveryDetails.epcsInTransit) {
        const { epcsInTransit } = state.deliveryDetails;

        for (const epc of epcs) {
          const epcIndex = epcsInTransit.findIndex(
            ({ epcCode }) => epcCode === epc
          );

          if (epcIndex !== -1) {
            epcsInTransit[epcIndex].lastEpcStatus = payload.status;
          }
        }
      }
    },
    initCheckedEpcs: state => {
      state.checkedEpcs = [];
    },
    initManageDeliveryState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeliveryDetails.pending, state => {
        state.fetchDeliveryDetailsHasError = undefined;
        state.fetchDeliveryDetailsIsLoading = true;
      })
      .addCase(fetchDeliveryDetails.fulfilled, (state, { payload }) => {
        state.deliveryDetails = payload;
        state.fetchDeliveryDetailsIsLoading = false;
      })
      .addCase(fetchDeliveryDetails.rejected, (state, { payload }) => {
        state.fetchDeliveryDetailsIsLoading = false;
        state.fetchDeliveryDetailsHasError = payload as ApiError;
      });
  },
});

export const {
  setSearchedStoreCode,
  setSearchedStoreCodes,
  setSearchedDeliveryCode,
  setSearchedDeliveryCodes,
  setShouldFetchDeliveries,
  setDeliveryStatuses,
  setManagedDeliveryInUse,
  checkEpcs,
  checkEpc,
  changeEpcStatus,
  initCheckedEpcs,
  initManageDeliveryState,
} = manageDeliverySlice.actions;
export default manageDeliverySlice.reducer;
