import React, { useState } from 'react';
import { Typography } from '@/components/ui/Typography';
import styled, { css } from 'styled-components';
import { StockItemDetail } from '../../../api';
import { UIBox } from '../../../components/ui/Box';
import { ProductImage } from '../../../components/ui/ProductImage';
import { Product } from '../../../types/enum';
import {
  StyledProductContent,
  StyledContentInfo,
  StyledAccordion,
  StyledDescription,
  StyledEpcCode,
  StyledAccordionItemContent,
} from '../../CycleCount/listItemStyle';
import { useTranslation } from 'react-i18next';
import { UIAction } from '@/components/ui/Action';
import { useSelector } from '@/hooks/useSelector';
import { isStockSummaryPrinter } from '@/utils/user';

const StyledGridMediaQueries = css<{ $query?: boolean }>`
  ${({ theme, $query }): string =>
    `${theme.breakpoints.down('md')} {
      padding: 18px 16px;
      grid-template-columns: ${
        $query ? '1fr 120px 100px 100px' : 'auto 100px 100px'
      };
    }`}

  ${({ theme, $query }): string =>
    `${theme.breakpoints.down('sm')} {
      padding: 18px 16px;
      grid-template-columns: ${
        $query ? '1fr 120px 100px 100px' : 'auto 100px 100px'
      };
    }`}
`;
const StyledGridColumns = css<{ $query?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $query }): string =>
    $query ? '1fr 120px 100px 100px' : 'auto 100px 100px'};
  gap: 48px;
`;

const StyledListGrid = styled(UIBox)<{ $query?: boolean }>`
  padding: 0 24px;

  ${StyledGridColumns}
  ${StyledGridMediaQueries}
`;

const StyledListGridItem = styled(UIBox)`
  padding: 16px 0;
  text-transform: capitalize;
  justify-self: flex-start;
  flex-direction: column;
  margin-left: 15px;
`;

const StyledAccordionContent = styled(UIBox)<{ ml: string }>`
  flex-direction: column;
  padding: 18px 26px 24px 145px;
  margin-left: ${({ ml }): string => ml};
`;

interface Props {
  item: StockItemDetail;
  index: number;
  onPrintActionClick: (epcCode: string, upcCode: string) => void;
  query: Partial<{
    brand: string;
    upcCode: string;
    commodity: string;
    epcCode: string;
    modelCode: string;
  }>;
}

export const ItemGridList: React.FC<Props> = ({
  item,
  index,
  query,
  onPrintActionClick,
}) => {
  const {
    brandCode,
    brandDescription,
    commodity,
    stock,
    transit,
    upcCode,
    imageUrl,
    colorCode,
    modelCode,
    styleName,
    epcCodes,
  } = item;
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false);
  const accordionClickHandler = (): void => setAccordionExpanded(p => !p);
  const hasSingleEpc = epcCodes && epcCodes.length === 1;

  const user = useSelector(state => state.user);
  const userCanPrint = isStockSummaryPrinter(user);

  const onPrintClick = (epcCode: string): void => {
    onPrintActionClick(epcCode, upcCode || '');
  };

  return (
    <UIBox flexDirection="column" width="100%">
      <StyledListGrid
        key={`${brandDescription}-${index}-item`}
        $query={
          !!(query.brand || query.upcCode || query.epcCode || query.modelCode)
        }
      >
        <StyledListGridItem>
          <StyledProductContent $type={Product.NOT_FOUND}>
            <StyledContentInfo>
              {epcCodes && epcCodes.length > 1 && (
                <StyledAccordion
                  expanded={accordionExpanded}
                  onClick={accordionClickHandler}
                />
              )}
              <ProductImage
                imageUrl={imageUrl}
                colorCode={colorCode}
                modelCode={modelCode}
                brandCode={brandCode}
                width={70}
              />
              <UIBox flexDirection="column">
                <StyledDescription>
                  <Typography size="lg" font="heavy" as="span">
                    {brandCode} - {brandDescription} - {styleName}
                  </Typography>
                  <Typography
                    margin="8px 0 0 0"
                    size="md"
                    font="book"
                    as="span"
                  >
                    {upcCode} - {modelCode} - {commodity}
                    {hasSingleEpc && (
                      <StyledEpcCode> - {epcCodes?.[0]}</StyledEpcCode>
                    )}
                  </Typography>
                </StyledDescription>
              </UIBox>
            </StyledContentInfo>
          </StyledProductContent>
        </StyledListGridItem>
        <StyledListGridItem>
          <UIBox flexDirection="column">
            <UIBox alignSelf="end">
              <Typography>
                {t('itemQuantity', {
                  itemQuantity: epcCodes && epcCodes.length,
                })}
              </Typography>
            </UIBox>
          </UIBox>
        </StyledListGridItem>
        <StyledListGridItem style={{ justifySelf: 'center' }}>
          <Typography font="medium">{transit}</Typography>
        </StyledListGridItem>
        <StyledListGridItem
          style={{ justifySelf: 'center', alignItems: 'center' }}
        >
          <Typography font="medium">{stock}</Typography>
          {hasSingleEpc && userCanPrint && (
            <UIBox mt={1}>
              <UIAction
                label={t('print')}
                icon="print"
                onClick={(): void => onPrintClick(epcCodes?.[0] || '')}
              />
            </UIBox>
          )}
        </StyledListGridItem>
      </StyledListGrid>
      {epcCodes && epcCodes.length > 1 && accordionExpanded && (
        <StyledAccordionContent ml="0px">
          {epcCodes?.map(epcCode => (
            <StyledAccordionItemContent key={epcCode}>
              <UIBox
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography margin="0 0 0 12px" color="grey">
                  {epcCode}
                </Typography>
                {userCanPrint && (
                  <UIAction
                    label={t('print')}
                    icon="print"
                    onClick={(): void => onPrintClick(epcCode)}
                  />
                )}
              </UIBox>
            </StyledAccordionItemContent>
          ))}
        </StyledAccordionContent>
      )}
    </UIBox>
  );
};
