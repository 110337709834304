import mqtt, { MqttClient } from 'mqtt';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type {
  MqttConnectionStatus,
  MQTTContextType,
  NewMessage,
} from '@/types/mqtt';
import { Device } from '@/api';

const getClientUniqueId = (): string => {
  const key = 'mqtt-client-id';
  const savedId = window.sessionStorage.getItem(key);

  if (savedId) {
    return savedId;
  } else {
    const id = uuidv4();
    window.sessionStorage.setItem(key, id);
    return id;
  }
};

export const MQTTContext = React.createContext<MQTTContextType>({
  client: undefined,
  newMessage: undefined,
  mqttConnectionStatus: undefined,
  setDeviceIdOnMqttClient: () => {},
});

const MQTTProvider: React.FC<{ brokerAddress: string }> = ({
  brokerAddress,
  children,
}) => {
  const [client, setClient] = useState<MqttClient>();
  const [newMessage, setNewMessage] = useState<NewMessage | undefined>();
  const [deviceId, setDeviceIdOnMqttClient] = useState<string>();
  const [clientId, setClientId] = useState<string>();
  const [mqttConnectionStatus, setMqttConnectionStatus] =
    useState<MqttConnectionStatus>();

  const deviceInStorage = sessionStorage.getItem('deviceInUse');
  const deviceToReconnect = useMemo(
    () => (deviceInStorage ? (JSON.parse(deviceInStorage) as Device) : null),
    [deviceInStorage]
  );

  useEffect(() => {
    if (!client) {
      const newClientId = getClientUniqueId();
      setClientId(newClientId);

      setClient(
        mqtt.connect(brokerAddress, {
          clientId,
          username: 'sensorid',
          password: 'S3n5or.1D@Mqtt!',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerAddress]);

  useEffect(() => {
    if (client) {
      if (deviceId) {
        client.end();

        const newClient = mqtt.connect(brokerAddress, {
          clientId,
          username: 'sensorid',
          password: 'S3n5or.1D@Mqtt!',
          will: {
            topic: `L/${deviceId}/CLIENT`,
            payload: 'free',
            qos: 1,
            retain: true,
          },
        });

        setClient(newClient);
      } else {
        client.end();
        const newClient = mqtt.connect(brokerAddress, {
          clientId,
          username: 'sensorid',
          password: 'S3n5or.1D@Mqtt!',
        });
        setClient(newClient);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerAddress, deviceId]);

  const handleClientConnection = (): void => {
    setMqttConnectionStatus('CONNECTED');
    console.log('👍 ✅ - Connection Online');
    console.log('📱 - MQTT Connected');
    if (deviceToReconnect && client) {
      setTimeout(() => {
        console.log(
          '📱 ~ lock ~ ',
          deviceToReconnect.deviceId,
          deviceToReconnect?.userName
        );

        setDeviceIdOnMqttClient(deviceToReconnect.deviceId);
        const lockTopic = `L/${deviceToReconnect.deviceId}/CLIENT`;
        client.publish(lockTopic, deviceToReconnect?.userName || 'free', {
          retain: true,
        });
      }, 5000);
    }
  };

  const handleClientDisconnection = (): void => {
    setMqttConnectionStatus('DISCONNECTED');
    console.log('🆘 ❌ - Connection Offline');
    console.log('📱 - MQTT Disconnected');
  };

  const handleClientMessage = (messageTopic: string, payload: Buffer): void => {
    console.log('📱 ~ publish message ~ ', messageTopic, {
      messageTopic,
      payload: payload.toString(),
    });

    setNewMessage({ topic: messageTopic, payload: payload.toString() });
  };

  useEffect(() => {
    client?.on('connect', handleClientConnection);
    client?.on('message', handleClientMessage);
  }, [client]);

  useEffect(() => {
    window.addEventListener('offline', handleClientDisconnection);
  }, [client]);

  useEffect(() => {
    window.addEventListener('online', handleClientConnection);
  }, [client]);

  return (
    <MQTTContext.Provider
      value={{
        client,
        newMessage,
        mqttConnectionStatus,
        setDeviceIdOnMqttClient,
      }}
    >
      {children}
    </MQTTContext.Provider>
  );
};

export default MQTTProvider;
