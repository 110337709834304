import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { Trans, useTranslation } from 'react-i18next';

import { UIList, UIListSubHeader } from '@/components/ui/List';
import { ScannedProductItem } from '@/components/layout/ScannedProductItem';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

import {
  removeStoreToStoreProductEpc,
  removeStoreToStoreProduct,
  searchItemsToTransfer,
  readdDeletedProduct,
  readdDeletedProductEpc,
  confirmStoreToStore,
  resetConfirmHasError,
  removeInspectionList,
  removeDamagesList,
  removeRecallList,
  removeCheckoutErrorList,
} from '@/features/s2s/storeToStoreSlice';
import {
  deleteTags,
  removeTag,
  removeTags,
  tagsMQTTDevice,
} from '@/features/devices/devicesSlice';

import { ScanDevicesService } from '@/api';

import { CTAContainer } from '@/components/layout/CTAContainer';
import { ModalScanDetails } from '@/components/layout/ModalScanDetails';
import { EnumMode } from '@/types/enum';
import { UIButton, UIButtonWithIcon } from '@/components/ui/Button';
import { UIAction } from '@/components/ui/Action';
import { theme } from '@/theme';
import { ModalProductDetails } from '@/components/layout/ModalProductDetails';
import { ScanDevice } from '@/types/device';
import { AppRoutes } from '@/app/routers';
import { UILoader } from '@/components/ui/Loader';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import useSROTrackEvent from '@/hooks/useSROTrackEvent';
import { UISwitch } from '@/components/ui/Switch';

//#region - Styled Components
const StyledHeader = styled(UIBox)`
  width: 100%;
  padding: 20px 24px;
  justify-content: space-between;
`;

const StyledNotificationButton = styled(UIButton)`
  font-size: 18px;
  width: auto;
  color: ${theme.palette.primary.contrastText} !important;
  border-color: ${theme.palette.primary.contrastText} !important;

  ${({ theme }): string =>
    `${theme.breakpoints.down('xs')} {
      margin-left: 0;

      button {
        width: 305px;
      }
    }`}
`;

export const StyledWrapper = styled(UIBox)`
  & > * {
    &:first-child {
      margin-right: 48px;
    }
  }

  ${({ theme }): string =>
    `${theme.breakpoints.down('md')} {
			flex-direction: column;
			align-items: end;

			& > * {
				&:first-child {
					margin-right: 0;
					margin-bottom: 12px;
				}
			}
    }`}
`;
//#endregion

const PageStoreToStoreSale: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const appInsights = useAppInsightsContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isScanningAgain, setIsScanningAgain] = useState<boolean>(false);
  const [isScanCompleted, setIsScanCompleted] = useState<boolean>(false);

  const [recallListIsOpen, setRecallListVisibility] = useState<boolean>(false);
  const [checkoutErrorListIsOpen, setCheckoutErrorListVisibility] =
    useState<boolean>(false);
  const [inspectionListIsOpen, setInspectionListVisibility] =
    useState<boolean>(false);
  const [damagesListIsOpen, setDamagesListVisibility] =
    useState<boolean>(false);

  const [confirmButtonClicked, setConfirmButtonClicked] =
    useState<boolean>(false);
  const [openScanModal, setScanModalVisibility] = useState(false);

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);
  const [finishButtonClicked, setFinishButtonClicked] =
    useState<boolean>(false);
  const [openProductModal, setProdcutModalVisibility] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();

  const { deviceInUse, tags, deleteTagsHasError } = useSelector(
    state => state.devices
  );
  const { store } = useSelector(state => state.currentStore);
  const { username } = useSelector(state => state.user);
  const [otherItemsVisible, setOtherItemsVisibility] = useState<boolean>(true);

  const {
    toStore,
    itemsToTransfer,
    itemsDamages,
    itemsQualityInspection,
    itemsRecall,
    itemsCheckoutError,
    itemsToTransferHasError,
    confirmHasError,
  } = useSelector(state => state.s2s);

  const trackStoreToStore = useSROTrackEvent(appInsights, 'Store to Store', {
    User: username,
    FromStore: '',
    ToStore: '',
    NrItems: 0,
  });

  const trackStoreToStoreDetails = useSROTrackEvent(
    appInsights,
    'Store to Store Details',
    {
      User: username,
      Store: store?.storeCode,
      UPC: '',
      EPC: '',
    }
  );

  const refreshBtnClick = async (): Promise<void> => {
    setIsLoading(true);
    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });

    if (deviceTags && deviceTags.length > 0) {
      dispatch(tagsMQTTDevice(deviceTags));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  useEffect(() => {
    if (tags && tags.length > 0) {
      dispatch(searchItemsToTransfer(tags));
    }
  }, [dispatch, tags]);

  useEffect(() => {
    if (itemsToTransferHasError) {
      setAlertSnackbarVisibility(true);
    }
  }, [itemsToTransferHasError]);

  const checkoutErrorItemsCount = itemsCheckoutError.reduce(
    (acc: number, item) => acc + Number(item.epcCodes.epcCodes?.length),
    0
  );

  const recallItemsCount = itemsRecall.reduce(
    (acc: number, item) => acc + Number(item.epcCodes.epcCodes?.length),
    0
  );

  const inspectionItemsCount = itemsQualityInspection.reduce(
    (acc: number, item) => acc + Number(item.epcCodes.epcCodes?.length),
    0
  );

  const damagesItemsCount = itemsDamages.reduce(
    (acc: number, item) => acc + Number(item.epcCodes.epcCodes?.length),
    0
  );

  const itemsCount = itemsToTransfer.reduce(
    (acc: number, item) => acc + Number(item.epcCodes.epcCodes?.length),
    0
  );

  const otherItems =
    inspectionItemsCount +
    damagesItemsCount +
    recallItemsCount +
    checkoutErrorItemsCount;

  const onProductClick = (e: React.MouseEvent, upcCode: string): void => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (
      typeof target.className === 'object' ||
      target.nextElementSibling instanceof SVGElement ||
      target.className.includes('Accordion')
    )
      return;

    setSelectedProduct(upcCode);
    setProdcutModalVisibility(true);
  };

  const onFinishScanClick = async (): Promise<void> => {
    try {
      const storage = sessionStorage.getItem('deviceInUse');

      if (!storage) return;

      const deviceInStorage = JSON.parse(storage) as Required<ScanDevice>;

      setFinishButtonClicked(true);
      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId! || deviceInStorage.deviceId,
      });

      if (mode === 'no mode') {
        setFinishButtonClicked(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId! || deviceInStorage.deviceId,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setFinishButtonClicked(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());

        setIsScanCompleted(true);
        setFinishButtonClicked(false);
      }, 2000);
    } catch (err) {
      setFinishButtonClicked(false);
      setDeviceError(true);
    }
  };

  const onQuitClick = (): void => {
    history.push(AppRoutes.STORE_TRANSFER_HISTORY);
  };

  const onScanClick = (): void => {
    setIsScanningAgain(true);
    setScanModalVisibility(true);
  };

  const onConfirmClick = async (): Promise<void> => {
    try {
      setConfirmButtonClicked(true);

      const upcCodes = itemsToTransfer.map(item => ({
        upcCode: item.upcCode,
        epcs: item.epcCodes.epcCodes!.map(epc => epc),
      }));

      await dispatch(resetConfirmHasError());

      await dispatch(confirmStoreToStore(upcCodes)).unwrap();

      trackStoreToStore({
        User: username,
        FromStore: store?.storeCode!,
        ToStore: toStore.code,
        NrItems: itemsToTransfer.map(item => item.epcCodes.epcCodes).flat()
          .length,
      });

      trackStoreToStoreDetails({
        User: username,
        Store: store?.storeCode,
        UPC: itemsToTransfer.map(item => item.upcCode).join(', '),
        EPC: itemsToTransfer
          .map(item => item.epcCodes.epcCodes)
          .flat()
          .join(', '),
      });

      setConfirmButtonClicked(false);
      history.push(`${AppRoutes.PDF_DOWNLOAD_STORE_TRANSFER}`);
    } catch {
      setConfirmButtonClicked(false);
      setConfirmError(true);
    }
  };
  const handleRemoveSaleProduct = (upcCode: string, epcCode: string): void => {
    const snackbarKey = enqueueSnackbar(t('sale.removeArticle'), {
      action: (
        <StyledNotificationButton
          label={t('cancel')}
          outlined
          onClick={(): void => {
            dispatch(readdDeletedProduct(upcCode));
            dispatch(tagsMQTTDevice([{ epc: epcCode }]));
            closeSnackbar(snackbarKey);
          }}
        />
      ),
      autoHideDuration: 6000,
    });

    dispatch(removeStoreToStoreProduct(upcCode));
    dispatch(removeTag(epcCode));
  };

  const handleRemoveSaleEpc = (upcCode: string, epcCode: string): void => {
    const snackbarKey = enqueueSnackbar(t('sale.removeArticle'), {
      action: (
        <StyledNotificationButton
          label={t('cancel')}
          outlined
          onClick={(): void => {
            dispatch(readdDeletedProductEpc({ upcCode, epcCode }));
            dispatch(tagsMQTTDevice([{ epc: epcCode }]));
            closeSnackbar(snackbarKey);
          }}
        />
      ),
      autoHideDuration: 6000,
    });

    dispatch(removeStoreToStoreProductEpc({ upcCode, epcCode }));
    dispatch(removeTag(epcCode));
  };

  const handleRemoveCheckoutErrorList = (): void => {
    const epcs = itemsCheckoutError
      .flatMap(({ epcCodes }) => epcCodes)
      .flatMap(item => item.epcCodes!);

    dispatch(removeTags(epcs));
    dispatch(removeCheckoutErrorList());
  };

  const handleRemoveRecallList = (): void => {
    const epcs = itemsRecall
      .flatMap(({ epcCodes }) => epcCodes)
      .flatMap(item => item.epcCodes!);

    dispatch(removeTags(epcs));
    dispatch(removeRecallList());
  };

  const handleRemoveInspectionList = (): void => {
    const epcs = itemsQualityInspection
      .flatMap(({ epcCodes }) => epcCodes)
      .flatMap(item => item.epcCodes!);

    dispatch(removeTags(epcs));
    dispatch(removeInspectionList());
  };

  const handleRemoveDamagesList = (): void => {
    const epcs = itemsDamages
      .flatMap(({ epcCodes }) => epcCodes)
      .flatMap(item => item.epcCodes!);

    dispatch(removeTags(epcs));
    dispatch(removeDamagesList());
  };

  return (
    <>
      <ModalScanDetails
        open={openScanModal}
        onClose={(): void => setScanModalVisibility(false)}
        hideModes={[EnumMode.FIND]}
        isScanningAgain={isScanningAgain}
        setIsScanCompleted={setIsScanCompleted}
      />
      <ModalProductDetails
        open={openProductModal}
        onClose={(): void => setProdcutModalVisibility(false)}
        productCode={selectedProduct}
      />
      <StyledHeader>
        <Typography font="heavy" size="lg" as="h1">
          {`${t('store_to_store.send_items')}`}:{' '}
          <Typography font="light">
            {toStore.code} {toStore.address}
          </Typography>
        </Typography>
        <StyledWrapper>
          {isLoading ? (
            <UILoader size={20} />
          ) : (
            <UIButtonWithIcon
              label={t('refresh')}
              startIcon={<RefreshIcon />}
              onClick={(): Promise<void> => refreshBtnClick()}
              disabled={!deviceInUse}
            />
          )}
          <UISwitch
            checked={otherItemsVisible}
            checkedLabel={`${t('otherItems')}: ${otherItems || 0}`}
            unCheckedLabel={`${t('otherItems')}: ${otherItems || 0}`}
            onChange={(): void => setOtherItemsVisibility(p => !p)}
          />
        </StyledWrapper>
      </StyledHeader>

      {otherItemsVisible && (
        <>
          {itemsCheckoutError.length > 0 && (
            <UIList
              subheader={
                <UIListSubHeader
                  title={`${t('items')} - ${t('missingCheckoutErrorStatus')}`}
                  itemsCount={checkoutErrorItemsCount}
                  accordion={{
                    expanded: checkoutErrorListIsOpen,
                    toggleAccordionState: setCheckoutErrorListVisibility,
                  }}
                  actions={
                    <UIAction
                      icon="delete"
                      onClick={handleRemoveCheckoutErrorList}
                      label={t('remove')}
                      disabled={!isScanCompleted}
                    />
                  }
                />
              }
              padding={[0, 24]}
              margin={[0, 0, 24]}
              bordered
              backgrounded
            >
              {checkoutErrorListIsOpen && (
                <>
                  {itemsCheckoutError.map((productItem, index) => (
                    <ScannedProductItem
                      $v2={
                        productItem.epcCodes.epcCodes &&
                        productItem.epcCodes.epcCodes.length > 1
                      }
                      key={`${productItem.brandCode}-${index}-product`}
                      productItem={productItem}
                      handleRemoveSaleEpc={handleRemoveSaleEpc}
                      handleRemoveSaleProduct={handleRemoveSaleProduct}
                      onClick={(e: React.MouseEvent): void =>
                        onProductClick(e, productItem.upcCode)
                      }
                      disableActions={!isScanCompleted}
                    />
                  ))}
                </>
              )}
            </UIList>
          )}

          {itemsRecall.length > 0 && (
            <UIList
              subheader={
                <UIListSubHeader
                  title={`${t('items')} - ${t('recalledStatus')}`}
                  itemsCount={recallItemsCount}
                  accordion={{
                    expanded: recallListIsOpen,
                    toggleAccordionState: setRecallListVisibility,
                  }}
                  actions={
                    <UIAction
                      icon="delete"
                      onClick={handleRemoveRecallList}
                      label={t('remove')}
                      disabled={!isScanCompleted}
                    />
                  }
                />
              }
              padding={[0, 24]}
              margin={[0, 0, 24]}
              bordered
              backgrounded
            >
              {recallListIsOpen && (
                <>
                  {itemsRecall.map((productItem, index) => (
                    <ScannedProductItem
                      $v2={
                        productItem.epcCodes.epcCodes &&
                        productItem.epcCodes.epcCodes.length > 1
                      }
                      key={`${productItem.brandCode}-${index}-product`}
                      productItem={productItem}
                      handleRemoveSaleEpc={handleRemoveSaleEpc}
                      handleRemoveSaleProduct={handleRemoveSaleProduct}
                      onClick={(e: React.MouseEvent): void =>
                        onProductClick(e, productItem.upcCode)
                      }
                      disableActions={!isScanCompleted}
                    />
                  ))}
                </>
              )}
            </UIList>
          )}

          {itemsQualityInspection.length > 0 && (
            <UIList
              subheader={
                <UIListSubHeader
                  title={`${t('items')} - ${t('qualityInspectionStatus')}`}
                  itemsCount={inspectionItemsCount}
                  accordion={{
                    expanded: inspectionListIsOpen,
                    toggleAccordionState: setInspectionListVisibility,
                  }}
                  actions={
                    <UIAction
                      icon="delete"
                      onClick={handleRemoveInspectionList}
                      label={t('remove')}
                      disabled={!isScanCompleted}
                    />
                  }
                />
              }
              padding={[0, 24]}
              margin={[0, 0, 24]}
              bordered
              backgrounded
            >
              {inspectionListIsOpen && (
                <>
                  {itemsQualityInspection.map((productItem, index) => (
                    <ScannedProductItem
                      $v2={
                        productItem.epcCodes.epcCodes &&
                        productItem.epcCodes.epcCodes.length > 1
                      }
                      key={`${productItem.brandCode}-${index}-product`}
                      productItem={productItem}
                      handleRemoveSaleEpc={handleRemoveSaleEpc}
                      handleRemoveSaleProduct={handleRemoveSaleProduct}
                      onClick={(e: React.MouseEvent): void =>
                        onProductClick(e, productItem.upcCode)
                      }
                      disableActions={!isScanCompleted}
                    />
                  ))}
                </>
              )}
            </UIList>
          )}

          {itemsDamages.length > 0 && (
            <UIList
              subheader={
                <UIListSubHeader
                  title={`${t('items')} - ${t('damagedStatus')}`}
                  itemsCount={damagesItemsCount}
                  accordion={{
                    expanded: damagesListIsOpen,
                    toggleAccordionState: setDamagesListVisibility,
                  }}
                  actions={
                    <UIAction
                      icon="delete"
                      onClick={handleRemoveDamagesList}
                      label={t('remove')}
                      disabled={!isScanCompleted}
                    />
                  }
                />
              }
              padding={[0, 24]}
              margin={[0, 0, 24]}
              bordered
              backgrounded
            >
              {damagesListIsOpen && (
                <>
                  {itemsDamages.map((productItem, index) => (
                    <ScannedProductItem
                      $v2={
                        productItem.epcCodes.epcCodes &&
                        productItem.epcCodes.epcCodes.length > 1
                      }
                      key={`${productItem.brandCode}-${index}-product`}
                      productItem={productItem}
                      handleRemoveSaleEpc={handleRemoveSaleEpc}
                      handleRemoveSaleProduct={handleRemoveSaleProduct}
                      onClick={(e: React.MouseEvent): void =>
                        onProductClick(e, productItem.upcCode)
                      }
                      disableActions={!isScanCompleted}
                    />
                  ))}
                </>
              )}
            </UIList>
          )}
        </>
      )}

      {itemsToTransfer.length > 0 && (
        <UIList
          itemsCount={
            <Trans
              i18nKey="receiving.founditems"
              values={{ count: itemsCount }}
            />
          }
          padding={[0, 24]}
          margin={[otherItemsVisible ? 42 : 0, 0, 100]}
          bordered
          backgrounded
        >
          {itemsToTransfer.map((productItem, index) => (
            <ScannedProductItem
              $v2={
                productItem.epcCodes.epcCodes &&
                productItem.epcCodes.epcCodes.length > 1
              }
              $sale
              key={`${productItem.brandCode}-${index}-product`}
              productItem={productItem}
              handleRemoveSaleEpc={handleRemoveSaleEpc}
              handleRemoveSaleProduct={handleRemoveSaleProduct}
              onClick={(e: React.MouseEvent): void =>
                onProductClick(e, productItem.upcCode)
              }
              disableActions={!isScanCompleted}
            />
          ))}
        </UIList>
      )}

      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        autoHideDuration={60000}
        ClickAwayListenerProps={{
          onClickAway: (): void => setAlertSnackbarVisibility(true),
        }}
        message={
          confirmHasError
            ? typeof confirmHasError != 'boolean'
              ? confirmHasError.body?.errorMessage ||
                confirmHasError.body ||
                t('error.something_went_wrong')
              : t('error.something_went_wrong')
            : t('store_to_store.no_products')
        }
      />

      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />

      <ErrorSnackbar
        open={confirmError}
        setIsOpen={setConfirmError}
        errorMessage={
          confirmHasError?.body?.errorMessage ||
          confirmHasError?.body ||
          t('error.storeToStoreConfirm')
        }
      />

      {!isScanCompleted ? (
        <CTAContainer
          type="FINISH SCAN"
          onClick={onFinishScanClick}
          loading={finishButtonClicked}
        />
      ) : (
        <CTAContainer
          type="DEFAULT"
          onQuitClick={onQuitClick}
          onConfirmClick={onConfirmClick}
          disabledConfirm={
            itemsCheckoutError.length > 0 ||
            itemsQualityInspection.length > 0 ||
            itemsDamages.length > 0 ||
            itemsRecall.length > 0
          }
          onScanClick={onScanClick}
          loadingConfirm={confirmButtonClicked}
        />
      )}
    </>
  );
};

export default PageStoreToStoreSale;
